<template>
    <div class="footer-content">
        <div class="app-name">BRA Communicator</div>
        <div class="unread-messages" v-if="this.$store.getters.getUnreadCount > 0">
            <div>Unread Messages:</div>
            <div class="message-count">{{ this.$store.getters.getUnreadCount }}</div>
        </div>
        <div class="login-username" v-if="this.$store.getters.isAuthenticated">
            <div>Logged in as: {{ currentUserName }}</div>
            <div class="site-modes" v-if="displaySiteModeSwitch">
                View site as:
                <select
                    class="select-css"
                    v-model="selected_mode"
                    v-on:change="handleSiteModeChange"
                >
                    <option v-for="mode in site_modes" v-bind:key="mode">
                        {{ mode }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";
export default {
    name: "Footer",
    data() {
        return {
            site_modes: [],
            selected_mode: "",
        };
    },
    mounted() {
        this.$nextTick(function () {
            this.site_modes = config.site_modes;
            this.selected_mode = this.$store.state.site_view_mode;
        });
    },
    computed: {
        currentUserName: function () {
            return this.$store.state.name;
        },
        displaySiteModeSwitch: function () {
            return this.$store.getters.hasPermission("admin");
        },
    },
    methods: {
        handleSiteModeChange() {
            this.$store.commit("setSiteViewMode", this.selected_mode);
        },
    },
};
</script>

<style scoped>
.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
    width: 100%;
    border-top: 1px solid #777;
}
.unread-messages {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}
.message-count {
    font: 12px Verdana;
    color: #fff;
    border-radius: 3px;
    background-color: rgba(212, 19, 13, 1);
    padding: 1px 3px;
    margin-left: 5px;
}
.app-name {
    padding-left: 15px;
}
.login-username {
    color: #aaa;
    font-size: smaller;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.site-modes {
    margin-left: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #999;
}
.select-css {
    display: block;
    font-size: smaller;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.2em 1.5em 0.2em 0.5em;
    box-sizing: border-box;
    margin-left: 5px;
    margin-right: 0px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}

.select-css option {
    font-weight: normal;
}
</style>

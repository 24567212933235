<template>
    <div class="phone-list-search-container">
        <div class="phone-lists">
            <div
                v-for="phone_list in phone_lists_list"
                v-bind:key="phone_list.id"
                class="phone-list"
            >
                <div class="phone-list-header">
                    <div>{{ phone_list.description }}</div>
                    <div>
                        <input
                            type="button"
                            class="styled-button mini-button"
                            @click="checkAllPhoneList(phone_list.id)"
                            value="Check All"
                        />
                        <input
                            type="button"
                            class="styled-button mini-button"
                            @click="unCheckAllPhoneList(phone_list.id)"
                            value="Clear all"
                        />
                    </div>
                </div>
                <table>
                    <tr
                        v-for="user_id in JSON.parse(phone_list.user_list)"
                        v-bind:key="user_id"
                    >
                        <td>
                            <input
                                type="checkbox"
                                v-bind:name="user_id"
                                v-bind:value="user_id"
                                v-model="user_list_checkboxes"
                            />
                        </td>
                        <td>{{ getUserObjById(user_id).fullname }}</td>
                        <td>
                            {{
                                getUserDirectoryLocationById(
                                    getUserObjById(user_id).location_id
                                ).location_name
                            }}
                        </td>
                        <td>{{ getUserObjById(user_id).phone }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="send-message">
            <div class="send-message-container">
                <div class="send-message-note">
                    Note: <strong>DO NOT</strong> include patient data in this message
                </div>
                <textarea v-model="textMessage" rows="5" placeholder="Message..."></textarea>
                <input
                    type="button"
                    class="styled-button"
                    value="Send Messages"
                    @click="sendMessages()"
                    :disabled="isSendDisabled"
                />
                <div class="send-message-success">
                    {{ success_message }}
                </div>
                <div class="send-message-header" v-if="user_list_checkboxes.length !== 0">
                    Phone text message will be sent to each of the following users:
                </div>
                <div>
                    <ul>
                        <li v-for="user_id in user_list_checkboxes" v-bind:key="user_id">
                            {{ getUserObjById(user_id).fullname }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";

export default {
    name: "PhoneListSearch",
    data() {
        return {
            selectedPhoneList: null,
            textMessage: "",
            success_message: "",
            user_list_checkboxes: [],
            sendDisabled: false,
        };
    },
    created() {
        this.$store.dispatch("fetchUserDirectory");
        this.$store.dispatch("fetchUserDirectoryLocations");
        this.$store.dispatch("fetchPhoneLists");
    },
    computed: {
        can_view() {
            return this.$store.getters.hasPermission("phone_list_view");
        },
        phone_lists_list() {
            return this.$store.state.phone_lists;
        },
        isSendDisabled() {
            return this.sendDisabled;
        },
    },
    methods: {
        getUserObjById: function (user_id) {
            let user_obj = this.$store.state.user_directory.find(function (user) {
                return user.id == user_id;
            });
            if (typeof user_obj == "undefined") {
                user_obj = {
                    fullname: "[user deactivated]",
                    location_id: "none",
                    location_name: "none",
                    phone: "none",
                };
            }
            return user_obj;
        },
        getUserDirectoryLocationById: function (location_id) {
            let loc_obj = this.$store.state.user_directory_locations.find(function (loc) {
                return loc.id == location_id;
            });
            if (typeof loc_obj == "undefined") {
                loc_obj = {
                    location_name: "none",
                };
            }
            return loc_obj;
        },
        getPhoneListObjById: function (phone_list_id) {
            let phone_list_obj = this.$store.state.phone_lists.find(function (phone_list) {
                return phone_list.id == phone_list_id;
            });
            return phone_list_obj;
        },
        checkAllPhoneList: function (phone_list_id) {
            let phone_list_obj = this.getPhoneListObjById(phone_list_id);
            let user_list = JSON.parse(phone_list_obj.user_list);
            /* this.user_list_checkboxes = this.user_list_checkboxes.concat(
                user_list.filter((item) => this.user_list_checkboxes.indexOf(item) < 0)
            ); */
            this.user_list_checkboxes = Array.from(
                new Set(this.user_list_checkboxes.concat(user_list))
            );
        },
        unCheckAllPhoneList: function (phone_list_id) {
            //this.user_list_checkboxes = [];
            let phone_list_obj = this.getPhoneListObjById(phone_list_id);
            let user_list = JSON.parse(phone_list_obj.user_list);
            let user_list_set = new Set(user_list);
            this.user_list_checkboxes = this.user_list_checkboxes.filter(function (value) {
                return !user_list_set.has(value);
            });
        },
        sendMessages: function () {
            this.sendDisabled = true;
            axios({
                method: "post",
                url: config.bra_api_url + "/phone-list-send",
                data: {
                    userId: this.$store.state.userId,
                    authToken: this.$store.state.authToken,
                    user_list: this.user_list_checkboxes,
                    phone_list_message: this.textMessage,
                },
            }).then((result) => {
                if (result.data.success) {
                    this.success_message = "Messages Sent!";
                    this.sendDisabled = false;
                }
            });
        },
    },
};
</script>

<style scoped>
.phone-list-search-container {
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.phone-lists {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 20px;
    background: #1e2833;
    border-radius: 10px;
}

.phone-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    color: #fff;
    margin-bottom: 5px;
    margin-top: 20px;
    text-align: left;
    width: 100%;
}

.phone-list table {
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;
    width: 100%;
}

.phone-list table thead {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: center;
}

.phone-list table td,
.phone-list table thead th {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.phone-list table thead th {
    cursor: pointer;
    background: #000;
    padding: 7px;
    color: #5af;
}

.phone-list table thead th:hover {
    color: #5cf;
}

.phone-list table tr:nth-child(odd):not(.noBackground) {
    background: #151515;
}

.phone-list table tr:nth-child(even):not(.noBackground) {
    background: #202020;
}

.send-message {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.send-message-success {
    color: #00ff99;
    padding: 20px;
    text-align: center;
}

.send-message-container {
    display: flex;
    flex-direction: column;
    background: #1e2833;
    border-radius: 10px;
    padding: 20px;
}

.send-message-header {
    margin-top: 10px;
    font-size: 16px;
    color: #fff;
}

.send-message-note {
    font-style: italic;
    font-size: 18px;
    color: rgb(255, 50, 50);
}

.send-message-note strong {
    text-decoration: underline;
    color: rgb(255, 0, 0);
}
textarea {
    width: 450px;
    font-size: 16px;
    background: #111;
    color: #ccc;
    margin-top: 20px;
    border-radius: 4px;
    padding: 7px;
}

textarea:focus {
    outline: none !important;
    border: 1px solid #5af;
}

.styled-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    margin: 10px;
    width: 200px;
}
.styled-button:hover {
    opacity: 0.8;
}
.styled-button:active {
    transform: translateY(1px);
}
.styled-button:focus {
    outline: none;
}
.styled-button::-moz-focus-inner {
    border: 0;
}

.mini-button {
    font-size: 14px;
    margin: 5px;
    padding: 3px;
    width: 100px;
}
</style>

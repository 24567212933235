let config;

config = {
    rc_api_url: "https://chat.bra-communicator.com",
    bra_api_url: "https://api.bra-communicator.com",
    //bra_api_url: "http://localhost:3001",
    site_modes: ["tech", "rad"],
    radiologist_list: [
        "johnholmen",
        "lukeroller",
        "mikefortney",
        "alexfeng",
        "brandonstott",
        "jeffreymaddock",
        "mjmcintee",
        "scottposwilko",
        "gtlarson",
        "charlesmccahery",
        "mattiwamoto",
        "markjohnson",
    ],
    peer_review_rad_reviewer: [
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
        "Dr. Grant Larson",
        "Dr. Jeff Maddock",
        "Dr. Brandon Stott",
        "Dr. Jeffrey Maddock",
        "Dr. Scott Poswilko",
    ],
    peer_review_rad_reviewed: [
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
        "Dr. Podoll",
        "Dr. Sirr",
        "Dr. Fogarty",
        "Dr. McIntee",
        "Dr. Grant Larson",
        "Dr. Jeff Maddock",
        "Dr. Brandon Stott",
        "Dr. Jeffrey Maddock",
        "Dr. Scott Poswilko",
        "Dr. Tanner Anderson",
        "Dr. Alexander Feng",
        "Dr. Charles McCahery",
        "Dr. Matt Iwamoto",
        "Dr. Mark Johnson ",
    ],
    fmo_audit_rad_list: [
        "BRA Group",
        "Dr. Doug Peterson",
        "Dr. John Holmen",
        "Dr. Luke Roller",
        "Dr. Mike Fortney",
        "Dr. Mike Schirado",
        "Dr. Podoll",
        "Dr. Sirr",
        "Dr. Fogarty",
        "Dr. McIntee",
        "Dr. Grant Larson",
        "Dr. Brandon Stott",
        "Dr. Jeffrey Maddock",
        "Dr. Scott Poswilko",
        "Dr. Tanner Anderson",
        "Dr. Alexander Feng",
    ],
    peer_review_scores: ["1", "2a", "2b", "3a", "3b", "4a", "4b"],
    modalities_list: ["RAD", "US", "CT", "MRI", "NM"],
    location_list: [
        "CHI St. Alexius Bismarck",
        "CHI Mandan",
        "CHI Garrison",
        "CHI Minot",
        "CHI Turtle Lake",
        "Ashley",
        "Bowman",
        "Elgin",
        "Harvey",
        "Hettinger",
        "Linton",
        "Sakakawea/Hazen",
        "Wishek",
        "IDI Fargo",
        "Other",
    ],
    rad_status_list: [
        "At the Hospital - Reading All Studies",
        "Not at the Hospital - Reading CT/MR/US/NM Only",
        "NOT CURRENTLY READING - PLEASE SEND TO TELERADIOLOGY",
        "Not on Call",
    ],
    rad_idi_status_list: [
        "Reading - Institute of Diagnostic Imaging",
        "Not on Call",
    ],
};

export default config;

<template>
    <div v-if="can_view" class="peer-review-search-container">
        <div class="search-filters">
            <div>
                <div class="filters-header">Date Range</div>
                <div class="search-filters-dates">
                    <div>From: <input type="date" v-model="date_from" /></div>
                    <div>To: <input type="date" v-model="date_to" /></div>
                </div>
            </div>
            <div class="search-filter-reviewers">
                <div class="filters-header">Included Radiologist Reviewed</div>
                <div v-for="rad in peer_review_rad_reviewed" v-bind:key="rad">
                    <input type="checkbox" v-bind:value="rad" v-model="checkedReviewed" />
                    {{ rad }}
                </div>
            </div>
            <div class="search-filter-reviewers">
                <div class="filters-header">Included Reviewers</div>
                <div v-for="rad in peer_review_rad_reviewer" v-bind:key="rad">
                    <input type="checkbox" v-bind:value="rad" v-model="checkedReviewer" />
                    {{ rad }}
                </div>
            </div>
            <div class="search-filter-scores">
                <div class="filters-header">Included Scores</div>
                <div v-for="score in peer_review_scores" v-bind:key="score">
                    <input type="checkbox" v-bind:value="score" v-model="checkedScores" />
                    {{ score }}
                </div>
            </div>
            <div class="search-filter-modalities">
                <div class="filters-header">Included Modalities</div>
                <div v-for="modality in peer_review_modalities" v-bind:key="modality">
                    <input
                        type="checkbox"
                        v-bind:value="modality"
                        v-model="checkedModalities"
                    />
                    {{ modality }}
                </div>
            </div>
            <div class="pagination-section-container">
                <button class="styled-button" @click="csvExport()">Export to CSV</button>
                <div class="pagination-section">
                    <button class="styled-button pagination-button" @click="prevPage">
                        &lt;
                    </button>
                    <input
                        type="number"
                        v-model="currentPage"
                        min="1"
                        v-bind:max="pages_max"
                    />
                    of
                    {{ pages_max }}
                    <button class="styled-button pagination-button" @click="nextPage">
                        &gt;
                    </button>
                </div>
            </div>
        </div>
        <div class="review-list">
            <table>
                <thead>
                    <th @click="sort('rad_name')">
                        Radiologist Reviewed
                        <span v-bind:class="getAscDescClass('rad_name')"></span>
                    </th>
                    <th @click="sort('reviewer_name')">
                        Reviewer
                        <span v-bind:class="getAscDescClass('reviewer_name')"></span>
                    </th>
                    <th @click="sort('date_reviewed')">
                        Date and Time
                        <span v-bind:class="getAscDescClass('date_reviewed')"></span>
                    </th>
                    <th @click="sort('accession')">
                        Accession Number
                        <span v-bind:class="getAscDescClass('accession')"></span>
                    </th>
                    <th @click="sort('exam_type')">
                        Exam Type
                        <span v-bind:class="getAscDescClass('exam_type')"></span>
                    </th>
                    <th @click="sort('score')">
                        Score
                        <span v-bind:class="getAscDescClass('score')"></span>
                    </th>
                    <th @click="sort('modality')">
                        Modality
                        <span v-bind:class="getAscDescClass('modality')"></span>
                    </th>
                    <th @click="sort('comments')">
                        Comments
                        <span v-bind:class="getAscDescClass('comments')"></span>
                    </th>
                </thead>
                <tr v-for="review in sortedReviews" v-bind:key="review.id">
                    <td>{{ review.rad_name }}</td>
                    <td>{{ review.reviewer_name }}</td>
                    <td>{{ review.date_reviewed }}</td>
                    <td>{{ review.accession }}</td>
                    <td>{{ review.exam_type }}</td>
                    <td>{{ review.score }}</td>
                    <td>{{ review.modality }}</td>
                    <td>{{ review.comments }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";

export default {
    name: "PeerReviewSearch",
    data() {
        return {
            currentSort: "date_reviewed",
            currentSortDir: "desc",
            pageSize: 20,
            currentPage: 1,
            date_from: "",
            date_to: "",
            checkedReviewer: [],
            checkedReviewed: [],
            checkedScores: [],
            checkedModalities: [],
        };
    },
    created() {
        this.$store.dispatch("fetchPeerReviews");
        this.checkedReviewer = this.peer_review_rad_reviewer;
        this.checkedReviewed = this.peer_review_rad_reviewed;
        this.checkedScores = this.peer_review_scores;
        this.checkedModalities = this.peer_review_modalities;
    },
    computed: {
        can_view() {
            return this.$store.getters.hasPermission("peer_review_view");
        },
        peer_reviews() {
            return this.$store.state.peer_reviews;
        },
        peer_review_rad_reviewed: function () {
            return config.peer_review_rad_reviewed;
        },
        peer_review_rad_reviewer: function () {
            return config.peer_review_rad_reviewer;
        },
        peer_review_scores() {
            return config.peer_review_scores;
        },
        peer_review_modalities() {
            return config.modalities_list;
        },
        filtered_reviews() {
            let peer_reviews_copy = [...this.peer_reviews];
            if (this.date_from) {
                peer_reviews_copy = peer_reviews_copy.filter((review) => {
                    if (!review.date_reviewed) {
                        return false;
                    }
                    let date = new Date(
                        review.date_reviewed.split(" ")[0] +
                            "T" +
                            review.date_reviewed.split(" ")[1] +
                            ".000Z"
                    );
                    let date_from = new Date(this.date_from);
                    return date_from <= date;
                });
            }
            if (this.date_to) {
                peer_reviews_copy = peer_reviews_copy.filter((review) => {
                    if (!review.date_reviewed) {
                        return false;
                    }
                    let date = new Date(
                        review.date_reviewed.split(" ")[0] +
                            "T" +
                            review.date_reviewed.split(" ")[1] +
                            ".000Z"
                    );
                    let date_to = new Date(this.date_to);
                    // Date plus 1 day for end of day
                    date_to.setDate(date_to.getDate() + 1);
                    return date <= date_to;
                });
            }
            peer_reviews_copy = peer_reviews_copy.filter((review) => {
                return this.checkedReviewer.includes(review.reviewer_name);
            });
            peer_reviews_copy = peer_reviews_copy.filter((review) => {
                return this.checkedReviewed.includes(review.rad_name);
            });
            peer_reviews_copy = peer_reviews_copy.filter((review) => {
                return this.checkedScores.includes(review.score);
            });
            peer_reviews_copy = peer_reviews_copy.filter((review) => {
                return this.checkedModalities.includes(review.modality);
            });
            return peer_reviews_copy;
        },
        pages_max() {
            return Math.ceil(this.filtered_reviews.length / this.pageSize);
        },
        sortedReviews: function () {
            return [...this.filtered_reviews]
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
    methods: {
        sort: function (s) {
            //if s == current sort, reverse
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },
        nextPage: function () {
            if (this.currentPage * this.pageSize < this.filtered_reviews.length)
                this.currentPage++;
        },
        prevPage: function () {
            if (this.currentPage > 1) this.currentPage--;
        },
        getAscDescClass: function (col_name) {
            if (this.currentSort == col_name) {
                if (this.currentSortDir == "asc") {
                    return "sort_up";
                } else {
                    return "sort_down";
                }
            } else {
                return "sort_none";
            }
        },
        csvExport() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(this.filtered_reviews[0]).join(","),
                ...this.filtered_reviews.map((item) =>
                    Object.values(item)
                        .map((inner_item) => {
                            return '"' + inner_item + '"';
                        })
                        .join(",")
                ),
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("target", "_blank");
            link.setAttribute("download", "bra_peer_reviews.csv");
            link.click();
        },
    },
};
</script>

<style scoped>
.peer-review-search-container {
    height: 750px;
    width: 100%;
    padding: 10px;
    margin: 10px;
    background: #1e2833;
}

.search-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-filters-dates {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.review-list table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.review-list table thead {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: left;
}

.review-list table td,
.review-list table thead th {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.review-list table thead th {
    cursor: pointer;
    background: #000;
    padding: 7px;
    color: #5af;
}

.review-list table thead th:hover {
    color: #5cf;
}

.review-list table tr:nth-child(odd):not(.noBackground) {
    background: #151515;
}

.review-list table tr:nth-child(even):not(.noBackground) {
    background: #202020;
}

.sort_none {
    width: 10px;
}

.sort_up {
    margin-left: 5px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.sort_down {
    margin-left: 5px;
    margin-bottom: 3px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.filters-header {
    font-size: 16px;
    color: #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;
    text-align: center;
}

.pagination-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pagination-section {
    width: 150px;
    margin: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pagination-section input[type="number"] {
    height: 30px;
    width: 30px;
    font-size: 18px;
    background: #000;
    font-size: medium;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    font-size: 16px;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    text-align: center;
}

.styled-button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    text-align: center;
    background: #214a80;
    padding: 10px;
    color: #fff;
    font-size: 18px;
}

.styled-button:hover {
    opacity: 0.8;
}

.styled-button:active {
    transform: translateY(1px);
}

.styled-button:focus {
    outline: none;
}

.styled-button::-moz-focus-inner {
    border: 0;
}

.pagination-button {
    background: #111;
    color: #fff;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>

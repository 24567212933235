<template>
    <div v-if="this.$store.getters.isRadiologist">
        <button class="oncall-button" v-on:click="showModal()">Change Rad On IDI</button>
        <div class="oncall-modal" v-if="is_modal_visible">
            <div class="modal-header">
                <div class="modal-title">Set IDI Radiologist</div>
                <div v-on:click="hideModal()" class="modal-close">X</div>
            </div>
            <div class="modal-warning">
                Note: this will set radiologists previously reading IDI to 
                <span class="color-red">Not on Call</span>.
            </div>
            <div class="modal-select">
                <div>Radiologist:</div>
                <div>
                    <select class="select-css" v-model="new_rad">
                        <option v-for="rad_user in rad_users" v-bind:key="rad_user">
                            {{ rad_user }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-select">
                <div>Status:</div>
                <div>
                    <select class="select-css" v-model="new_status">
                        <option v-for="status in status_options" v-bind:key="status">
                            {{ status }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-submit">
                <button class="oncall-button" v-on:click="submitOnCall()">Submit</button>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";
let status_list = config.rad_idi_status_list;
let rad_list = config.radiologist_list;
export default {
    name: "SetRadOnIDI",
    data() {
        return {
            is_modal_visible: false,
            status_options: status_list,
            rad_users: rad_list,
            new_rad: "",
            new_status: "",
        };
    },
    computed: {
        currentUserName: function () {
            return this.$store.state.userName;
        },
    },
    created() {
        if (this.rad_users.includes(this.currentUserName)) {
            this.new_rad = this.currentUserName;
        }
    },
    methods: {
        showModal() {
            this.is_modal_visible = true;
        },
        hideModal() {
            this.is_modal_visible = false;
        },
        submitOnCall() {
            if (this.new_status !== "" && this.new_rad !== "") {
                this.hideModal();
                axios({
                    method: "post",
                    url: config.bra_api_url + "/on-idi",
                    data: {
                        userId: this.$store.state.userId,
                        userToUpdate: this.new_rad,
                        authToken: this.$store.state.authToken,
                        newStatus: this.new_status,
                    },
                }).then(() => {
                    this.$store.dispatch("refreshUsers");
                });
            }
        },
    },
};
</script>

<style scoped>
.oncall-modal {
    position: fixed;
    top: 40px;
    left: 50px;
    border-radius: 10px;
    background: #222;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.75);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #2f343d;
    color: #ddd;
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 1px solid #ddd;
}

.modal-title {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px;
}

.modal-warning {
    padding: 10px;
    font-size: small;
}

.modal-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.modal-select div {
    padding: 5px;
}

.modal-submit {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
}

.modal-submit button {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
}

.color-red {
    color: #e04545;
}

.modal-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    font-size: larger;
    font-style: normal;
    color: #000;
    background: #aaa;
    padding: 5px;
    border-top-right-radius: 10px;
}

.oncall-button {
    margin-left: 10px;
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 14px;
}

.oncall-button:hover {
    opacity: 0.8;
}

.oncall-button:active {
    transform: translateY(1px);
}

.oncall-button:focus {
    outline: none;
}

.oncall-button::-moz-focus-inner {
    border: 0;
}

.select-css {
    display: block;
    font-size: small;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}

.select-css option {
    font-weight: normal;
}
</style>

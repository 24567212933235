<template>
    <div class="reference">
        <ReferenceDocumentsList />
    </div>
</template>

<script>
import ReferenceDocumentsList from "@/components/ReferenceDocumentsList.vue";

export default {
    name: "reference",
    components: {
        ReferenceDocumentsList,
    },
};
</script>

<style scoped>
.reference {
    text-align: left;
    display: inline-block;
}
</style>

<template>
    <div class="navbar-container">
        <div class="navbar">
            <div class="left-side">
                <button
                    v-if="canPhoneListView"
                    class="phone-text-button"
                    @click="$router.push('phone-list')"
                >
                    Phone List Texting System
                </button>
            </div>
            <div class="right-side">
                <nav class="nav-links">
                    <router-link class="nav-link" to="/">Home</router-link>
                    <router-link class="nav-link" to="/files" v-if="isLoggedIn"
                        >Files</router-link
                    >
                    <router-link
                        class="nav-link"
                        to="/user-directory"
                        v-if="canViewUserDirectory"
                    >
                        User Directory
                    </router-link>
                    <router-link class="nav-link" to="/peer-review" v-if="canPeerReview">
                        Peer Review
                    </router-link>
                    <router-link class="nav-link" to="/qc-metrics" v-if="canQCMetricsView">
                        QC Metrics
                    </router-link>
                    <router-link class="nav-link" to="/fmo-audits" v-if="canFMOAuditsView">
                        FMO Audits
                    </router-link>
                    <router-link class="nav-link" to="/profile" v-if="isLoggedIn">
                        Profile
                    </router-link>
                    <router-link class="nav-link" to="/support">Support</router-link>
                    <router-link class="nav-link" to="/logout" v-if="isLoggedIn">
                        Logout
                    </router-link>
                    <router-link class="nav-link" to="/login" v-if="!isLoggedIn">
                        Login
                    </router-link>
                </nav>
            </div>
        </div>
        <div><RadOnCall /></div>
    </div>
</template>

<script>
import RadOnCall from "@/components/RadOnCall.vue";
//import UserList from "@/components/UserList.vue";
//import SetRadOnCall from "@/components/SetRadOnCall.vue";
import config from "@/config.js";
import axios from "axios";

export default {
    name: "NavBar",
    components: {
        RadOnCall,
        //UserList,
        //SetRadOnCall,
    },
    data() {
        return {
            timer: "",
            timer_interval: 30000,
            logged_out_rad_on_call: "",
            logged_out_rad_on_call_status: "",
        };
    },
    created() {
        this.getRadOnCall();
        this.timer = setInterval(() => {
            this.getRadOnCall();
        }, this.timer_interval);
    },
    computed: {
        statusColor() {
            if (
                this.logged_out_rad_on_call_status == "At the Hospital - Reading All Studies"
            ) {
                return "green";
            } else if (
                this.logged_out_rad_on_call_status ==
                "Not at the Hospital - Reading CT/MR/US/NM Only"
            ) {
                return "yellow";
            } else {
                return "red";
            }
        },
        isLoggedIn() {
            return this.$store.getters.isAuthenticated;
        },
        canPeerReview() {
            return this.$store.getters.hasPermission("peer_review_view");
        },
        canViewUserDirectory() {
            return this.$store.getters.hasPermission("peer_review_view");
        },
        canQCMetricsView() {
            return this.$store.getters.hasPermission("qc_metrics_view");
        },
        canFMOAuditsView() {
            return this.$store.getters.hasPermission("fmo_audits_view");
        },
        canPhoneListView() {
            return this.$store.getters.hasPermission("phone_list_view");
        },
    },
    methods: {
        getRadOnCall() {
            if (this.$store.getters.isAuthenticated) {
                this.logged_out_rad_on_call = "";
                this.logged_out_rad_on_call_status = "";
            } else {
                axios({
                    method: "post",
                    url: config.bra_api_url + "/rad-on-call",
                }).then((resp) => {
                    if (resp.data.success) {
                        this.logged_out_rad_on_call = resp.data.name;
                        this.logged_out_rad_on_call_status = resp.data.statusText;
                    } else {
                        this.logged_out_rad_on_call = "N/A";
                        this.logged_out_rad_on_call_status = "No Radiologist On Call";
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
.navbar {
    background: #333333;
    color: #dddddd;
    width: 100%;
    min-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #777;
}

.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.nav-link {
    padding: 5px;
}

.green {
    color: #00ff99;
}

.yellow {
    color: #f1f35f;
}

.red {
    color: #e04545;
}

.logged-out-rad-on-call {
    padding-left: 10px;
    font-size: 14px;
}

.logged-out-rad-on-call div {
    display: inline-block;
    padding-left: 15px;
}

.left-side {
    height: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.right-side {
    height: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    padding-left: 15px;
}

.rad-header {
    font-size: 18px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 5px;
}

.phone-text-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 16px;
}

.phone-text-button:hover {
    opacity: 0.8;
}

.phone-text-button:active {
    transform: translateY(1px);
}

.phone-text-button:focus {
    outline: none;
}

.phone-text-button::-moz-focus-inner {
    border: 0;
}

nav a {
    color: #5af;
    font-size: medium;
}

nav a:hover {
    color: #5cf;
}

nav a.router-link-exact-active {
    font-weight: bold;
    color: #42b983;
}

nav a.router-link-exact-active:hover {
    color: #56f0aa;
}

@media all and (max-width: 1900px) {
    nav a {
        font-size: 14px;
    }
    .rad-header {
        font-size: 14px;
    }
    .nav-link {
        padding: 4px;
    }
    .phone-text-button {
        font-size: 14px;
    }
}

@media all and (max-width: 1600px) {
    nav a {
        font-size: 12px;
    }

    .rad-header {
        font-size: 12px;
    }
    .nav-link {
        padding: 3px;
    }
    .phone-text-button {
        font-size: 12px;
    }
}
</style>

<template>
    <div class="gallery-container">
        <gallery
            :images="images"
            :index="index"
            :options="options"
            @close="index = null"
        ></gallery>
        <div class="image-container">
            <div class="image-item" v-for="(image, imageIndex) in images" :key="imageIndex">
                <div
                    class="image"
                    @click="index = imageIndex"
                    :style="{ backgroundImage: 'url(' + image.href + ')' }"
                ></div>
                <div class="image-description">{{ image.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import VueGallery from "vue-gallery";
export default {
    name: "ImageGallery",
    data: function () {
        return {
            images: [
                {
                    title: "ACR_TI-RADS",
                    description: "ACR_TI-RADS",
                    href: require("../assets/images/ACR_TI-RADS.png"),
                },
                {
                    title: "Carotid US",
                    description: "Carotid US",
                    href: require("../assets/images/carotidUS.jpg"),
                },
                {
                    title: "Contrast Reaction",
                    description: "Contrast Reaction (small)",
                    href: require("../assets/images/Contrast_reaction_small.png"),
                },
                {
                    title: "Contrast Extrav",
                    description: "Contrast Extravasation",
                    href: require("../assets/images/Contrast-extrav.jpg"),
                },
                {
                    title: "Critical Results",
                    description: "Critical Result Conditions",
                    href: require("../assets/images/critical-results.jpg"),
                },
                {
                    title: "CV Cardiovascular",
                    description: "Calcium score and coronary plaque",
                    href: require("../assets/images/CV_cardiovascular.png"),
                },
                {
                    title: "Cystic Renal Mass",
                    description: "Cystic Renal Mass",
                    href: require("../assets/images/CysticRenalMass.jpg"),
                },
                {
                    title: "Dermatome Map",
                    description: "Dermatome-map-1",
                    href: require("../assets/images/Dermatome-map-1.jpg"),
                },
                {
                    title: "Fleischner New",
                    description: "Fleischner-new",
                    href: require("../assets/images/Fleischner-new.jpg"),
                },
                {
                    title: "Incidental Adnexal Lesions",
                    description: "IncidentalAdnexalLesions",
                    href: require("../assets/images/IncidentalAdnexalLesions.jpg"),
                },
                {
                    title: "Incidental Thyroid Nodules",
                    description: "IncidentalThyroidNodules",
                    href: require("../assets/images/IncidentalThyroidNodules.jpg"),
                },
                {
                    title: "Pulmonary Fibrosis Criteria",
                    description: "Lancet Criteria for Pulmonary Fibrosis",
                    href: require("../assets/images/lancet-criteria-pulmonary-fibrosis.jpg"),
                },
                {
                    title: "Liver Sizes",
                    description: "liver-sizes-small",
                    href: require("../assets/images/liver-sizes-small.jpg"),
                },
                {
                    title: "Lymphoma PET Criteria",
                    description: "Lymphoma-PET-criteria",
                    href: require("../assets/images/Lymphoma-PET-criteria.jpg"),
                },
                {
                    title: "Myelination",
                    description: "Myelination",
                    href: require("../assets/images/Myelination.jpg"),
                },
                {
                    title: "Neck Nodes",
                    description: "necknodes",
                    href: require("../assets/images/necknodes.jpg"),
                },
                {
                    title: "Nonviable",
                    description: "Nonviable",
                    href: require("../assets/images/Nonviable.jpg"),
                },
                {
                    title: "Oral Contrast",
                    description: "oralContrast",
                    href: require("../assets/images/oralContrast.png"),
                },
                {
                    title: "Ovary1",
                    description: "ovary1",
                    href: require("../assets/images/ovary1.jpeg"),
                },
                {
                    title: "Ovary2",
                    description: "ovary2",
                    href: require("../assets/images/ovary2.jpeg"),
                },
                {
                    title: "Ovary3",
                    description: "ovary3",
                    href: require("../assets/images/ovary3.jpeg"),
                },
                {
                    title: "Pseudo-Progression",
                    description: "pseudoprogression",
                    href: require("../assets/images/pseudoprogression.png"),
                },
                {
                    title: "Pulmonary-Fibrosis-Table",
                    description: "Pulmonary Fibrosis Table",
                    href: require("../assets/images/pulmonary_fibrosis_table.jpg"),
                },
                {
                    title: "Shunt1",
                    description: "Shunt1-small",
                    href: require("../assets/images/Shunt1-small.jpg"),
                },
                {
                    title: "Shunt2",
                    description: "Shunt2-small",
                    href: require("../assets/images/Shunt2-small.jpg"),
                },
                {
                    title: "Solid Renal Mass",
                    description: "SolidRenalMass",
                    href: require("../assets/images/SolidRenalMass.jpg"),
                },
                {
                    title: "Thyroid Nodules",
                    description: "ThyroidNodules",
                    href: require("../assets/images/ThyroidNodules.jpg"),
                },
                {
                    title: "US after hours critical exams",
                    description: "US after hours critical exams",
                    href: require("../assets/images/US-after-hours-critical-exams.png"),
                },
                {
                    title: "Vascular Territories",
                    description: "vascularTerritories",
                    href: require("../assets/images/vascularTerritories.jpg"),
                },
                {
                    title: "Vasospasm",
                    description: "Vasospasm",
                    href: require("../assets/images/Vasospasm.jpg"),
                },
            ],
            index: null,
            options: {
                stretchImages: "contain",
            },
        };
    },
    components: {
        gallery: VueGallery,
    },
};
</script>

<style scoped>
.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding: 10px;
}

.image-item {
    flex: 0 1 auto;
    padding: 10px;
}

.image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    width: 120px;
    height: 120px;
    cursor: pointer;
}

.image-description {
    padding-top: 5px;
    font-size: medium;
    width: 120px;
}

.gallery-container {
    display: block;
}

@media all and (max-width: 2200px) {
    .image {
        width: 100px;
        height: 100px;
    }

    .image-description {
        width: 100px;
        font-size: medium;
    }
}

@media all and (max-width: 2000px) {
    .image {
        width: 90px;
        height: 90px;
    }

    .image-description {
        width: 90px;
        font-size: small;
    }
}

@media all and (max-width: 1800px) {
    .image {
        width: 80px;
        height: 80px;
    }

    .image-description {
        width: 80px;
        font-size: small;
    }
}

@media all and (max-width: 1600px) {
    .image {
        width: 70px;
        height: 70px;
    }

    .image-description {
        width: 70px;
        font-size: x-small;
    }
}

@media all and (max-width: 1400px) {
    .image {
        width: 60px;
        height: 60px;
    }

    .image-description {
        width: 60px;
        font-size: x-small;
    }
}

@media all and (max-width: 1200px) {
    .image {
        width: 50px;
        height: 50px;
    }

    .image-description {
        width: 50px;
        font-size: xx-small;
    }
}

@media all and (max-width: 900px) {
    .image {
        width: 40px;
        height: 40px;
    }

    .image-description {
        width: 40px;
        font-size: xx-small;
    }
}
</style>

<style>
/* Needed due to navbar pushing stuff down */
.slide-content {
    height: calc(100% - 200px) !important;
}
</style>

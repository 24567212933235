<template>
    <div class="login">
        <LoginForm />
    </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
    name: "login",
    components: {
        LoginForm,
    },
};
</script>

<style scoped>
.login {
    text-align: center;
    background-color: #111111;
}
</style>

<template>
    <div class="fmo-audit-container">
        <div class="form-container"><FMOAuditForm /></div>
        <div class="search-container"><FMOAuditSearch /></div>
    </div>
</template>

<script>
import FMOAuditForm from "@/components/FMOAuditForm.vue";
import FMOAuditSearch from "@/components/FMOAuditSearch.vue";

export default {
    name: "FMOAudit",
    components: {
        FMOAuditForm,
        FMOAuditSearch,
    },
};
</script>

<style scoped>
.fmo-audit-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 80vh;
}

.form-container {
    width: 490px;
}

.search-container {
    width: calc(100vw - 550px);
    min-width: 1100px;
}
</style>

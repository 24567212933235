<template>
    <div v-if="can_view" class="fmo-audits-search-container">
        <div class="search-filters">
            <div>
                <div class="filters-header">Date Range</div>
                <div class="search-filters-dates">
                    <div>From: <input type="date" v-model="date_from" /></div>
                    <div>To: <input type="date" v-model="date_to" /></div>
                </div>
            </div>
            <div class="search-filter-locations">
                <div class="filters-header">Included Locations</div>
                <div v-for="location in fmo_audits_locations" v-bind:key="location">
                    <input
                        type="checkbox"
                        v-bind:value="location"
                        v-model="checkedLocations"
                    />
                    {{ location }}
                </div>
            </div>
            <div class="search-filter-rads">
                <div class="filters-header">Included Radiologists (or groups)</div>
                <div v-for="rad in fmo_audit_rad_list" v-bind:key="rad">
                    <input type="checkbox" v-bind:value="rad" v-model="checkedRads" />
                    {{ rad }}
                </div>
            </div>
            <div class="pagination-section-container">
                <button class="styled-button" @click="csvExport()">Export to CSV</button>
                <div class="pagination-section">
                    <button class="styled-button pagination-button" @click="prevPage">
                        &lt;
                    </button>
                    <input
                        type="number"
                        v-model="currentPage"
                        min="1"
                        v-bind:max="pages_max"
                    />
                    of
                    {{ pages_max }}
                    <button class="styled-button pagination-button" @click="nextPage">
                        &gt;
                    </button>
                </div>
            </div>
        </div>
        <div class="fmo-audits-list">
            <table>
                <thead>
                    <th @click="sort('id')">
                        ID
                        <span v-bind:class="getAscDescClass('id')"></span>
                    </th>
                    <th @click="sort('datetime_begin')">
                        Begin
                        <span v-bind:class="getAscDescClass('datetime_begin')"></span>
                    </th>
                    <th @click="sort('datetime_end')">
                        End
                        <span v-bind:class="getAscDescClass('datetime_end')"></span>
                    </th>
                    <th @click="sort('rad_name')">
                        Name
                        <span v-bind:class="getAscDescClass('rad_name')"></span>
                    </th>
                    <th @click="sort('location')">
                        Location
                        <span v-bind:class="getAscDescClass('location')"></span>
                    </th>
                    <th @click="sort('false_negative')">
                        FN
                        <span v-bind:class="getAscDescClass('false_negative')"></span>
                    </th>
                    <th @click="sort('false_positive')">
                        FP
                        <span v-bind:class="getAscDescClass('false_positive')"></span>
                    </th>
                    <th @click="sort('true_negative')">
                        TN
                        <span v-bind:class="getAscDescClass('true_negative')"></span>
                    </th>
                    <th @click="sort('true_positive')">
                        TP
                        <span v-bind:class="getAscDescClass('true_positive')"></span>
                    </th>
                    <th @click="sort('false_positive_screening')">
                        FP1
                        <span
                            v-bind:class="getAscDescClass('false_positive_screening')"
                        ></span>
                    </th>
                    <th @click="sort('false_positive_biopsy_rec')">
                        FP2
                        <span
                            v-bind:class="getAscDescClass('false_positive_biopsy_rec')"
                        ></span>
                    </th>
                    <th @click="sort('false_positive_biopsy_done')">
                        FP3
                        <span
                            v-bind:class="getAscDescClass('false_positive_biopsy_done')"
                        ></span>
                    </th>
                    <th @click="sort('ppv1')">
                        PPV1
                        <span v-bind:class="getAscDescClass('ppv1')"></span>
                    </th>
                    <th @click="sort('ppv2')">
                        PPV2
                        <span v-bind:class="getAscDescClass('ppv2')"></span>
                    </th>
                    <th @click="sort('ppv3')">
                        PPV3
                        <span v-bind:class="getAscDescClass('ppv3')"></span>
                    </th>
                    <th @click="sort('sensitivity')">
                        Sensitivity
                        <span v-bind:class="getAscDescClass('sensitivity')"></span>
                    </th>
                    <th @click="sort('specificity')">
                        Specificity
                        <span v-bind:class="getAscDescClass('specificity')"></span>
                    </th>
                    <th @click="sort('cancer_detection_rate')">
                        CDR
                        <span v-bind:class="getAscDescClass('cancer_detection_rate')"></span>
                    </th>
                    <th @click="sort('recall_rate')">
                        RR
                        <span v-bind:class="getAscDescClass('recall_rate')"></span>
                    </th>
                    <th @click="sort('comments')">
                        Comments
                        <span v-bind:class="getAscDescClass('comments')"></span>
                    </th>
                </thead>
                <tr v-for="fmo_audit in sortedFMOAudits" v-bind:key="fmo_audit.id">
                    <td>{{ fmo_audit.id }}</td>
                    <td>{{ cleanDate(fmo_audit.datetime_begin) }}</td>
                    <td>{{ cleanDate(fmo_audit.datetime_end) }}</td>
                    <td>{{ fmo_audit.rad_name }}</td>
                    <td>{{ fmo_audit.location }}</td>
                    <td>{{ fmo_audit.false_negative }}</td>
                    <td>{{ fmo_audit.false_positive }}</td>
                    <td>{{ fmo_audit.true_negative }}</td>
                    <td>{{ fmo_audit.true_positive }}</td>
                    <td>{{ fmo_audit.false_positive_screening }}</td>
                    <td>{{ fmo_audit.false_positive_biopsy_rec }}</td>
                    <td>{{ fmo_audit.false_positive_biopsy_done }}</td>
                    <td>{{ fmo_audit.ppv1 }}</td>
                    <td>{{ fmo_audit.ppv2 }}</td>
                    <td>{{ fmo_audit.ppv3 }}</td>
                    <td>{{ fmo_audit.sensitivity }}</td>
                    <td>{{ fmo_audit.specificity }}</td>
                    <td>{{ fmo_audit.cancer_detection_rate }}</td>
                    <td>{{ fmo_audit.recall_rate }}</td>
                    <td>{{ fmo_audit.comments }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";

export default {
    name: "FMOAuditSearch",
    data() {
        return {
            currentSort: "id",
            currentSortDir: "desc",
            pageSize: 20,
            currentPage: 1,
            date_from: "",
            date_to: "",
            checkedLocations: [],
            checkedRads: [],
        };
    },
    mounted() {
        this.$nextTick(function () {
            this.$store.dispatch("fetchFMOAudits");
            this.checkedLocations = this.fmo_audits_locations;
            this.checkedRads = this.fmo_audit_rad_list;
        });
    },
    computed: {
        can_view() {
            return this.$store.getters.hasPermission("fmo_audit_view");
        },
        fmo_audits() {
            return this.$store.state.fmo_audits;
        },
        fmo_audits_locations() {
            return config.location_list;
        },
        fmo_audit_rad_list() {
            return config.fmo_audit_rad_list;
        },
        filtered_fmo_audits() {
            let fmo_audits_copy = [...this.fmo_audits];
            if (this.date_from) {
                fmo_audits_copy = fmo_audits_copy.filter((fmo_audit) => {
                    if (!fmo_audit.datetime_end) {
                        return false;
                    }
                    let date_from = new Date(this.date_from);
                    let date_end = new Date(
                        fmo_audit.datetime_end.split(" ")[0] +
                            "T" +
                            fmo_audit.datetime_end.split(" ")[1] +
                            ".000Z"
                    );
                    return date_from <= date_end;
                });
            }
            if (this.date_to) {
                fmo_audits_copy = fmo_audits_copy.filter((fmo_audit) => {
                    if (!fmo_audit.datetime_begin) {
                        return false;
                    }
                    let date_to = new Date(this.date_to);
                    // Date plus 1 day for end of day
                    date_to.setDate(date_to.getDate() + 1);
                    let date_begin = new Date(
                        fmo_audit.datetime_begin.split(" ")[0] +
                            "T" +
                            fmo_audit.datetime_begin.split(" ")[1] +
                            ".000Z"
                    );
                    return date_begin <= date_to;
                });
            }
            fmo_audits_copy = fmo_audits_copy.filter((audit) => {
                return this.checkedLocations.includes(audit.location);
            });
            fmo_audits_copy = fmo_audits_copy.filter((audit) => {
                return this.checkedRads.includes(audit.rad_name);
            });

            fmo_audits_copy = fmo_audits_copy.map((audit) => {
                var obj = Object.assign({}, audit);
                obj.ppv1 = this.PPV1_calc(obj.true_positive, obj.false_positive_screening);
                obj.ppv2 = this.PPV2_calc(obj.true_positive, obj.false_positive_biopsy_rec);
                obj.ppv3 = this.PPV3_calc(obj.true_positive, obj.false_positive_biopsy_done);
                obj.sensitivity = this.sensitivity_calc(obj.true_positive, obj.false_negative);
                obj.specificity = this.specificity_calc(
                    obj.true_negative,
                    obj.false_positive_screening
                );
                obj.cancer_detection_rate = this.cancer_detection_rate_calc(
                    obj.true_positive,
                    obj.true_negative,
                    obj.false_positive,
                    obj.false_negative
                );
                obj.recall_rate = this.recall_rate_calc(
                    obj.true_positive,
                    obj.true_negative,
                    obj.false_positive,
                    obj.false_negative
                );
                return obj;
            });

            return fmo_audits_copy;
        },
        pages_max() {
            return Math.ceil(this.filtered_fmo_audits.length / this.pageSize);
        },
        sortedFMOAudits: function () {
            return [...this.filtered_fmo_audits]
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
    methods: {
        sort: function (s) {
            //if s == current sort, reverse
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },
        cleanDate: function (dateStr) {
            return dateStr.substring(0, 10);
        },
        nextPage: function () {
            if (this.currentPage * this.pageSize < this.filtered_fmo_audits.length)
                this.currentPage++;
        },
        prevPage: function () {
            if (this.currentPage > 1) this.currentPage--;
        },
        getAscDescClass: function (col_name) {
            if (this.currentSort == col_name) {
                if (this.currentSortDir == "asc") {
                    return "sort_up";
                } else {
                    return "sort_down";
                }
            } else {
                return "sort_none";
            }
        },
        csvExport() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(this.filtered_fmo_audits[0]).join(","),
                ...this.filtered_fmo_audits.map((item) =>
                    Object.values(item)
                        .map((inner_item) => {
                            return '"' + inner_item + '"';
                        })
                        .join(",")
                ),
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("target", "_blank");
            link.setAttribute("download", "bra_fmo_audits.csv");
            link.click();
        },
        PPV1_calc: function (TP_num, FP1_num) {
            if (Number(TP_num) + Number(FP1_num) > 0) {
                return ((Number(TP_num) / (Number(TP_num) + Number(FP1_num))) * 100).toFixed(
                    2
                );
            } else {
                return 0;
            }
        },
        PPV2_calc: function (TP_num, FP2_num) {
            if (Number(TP_num) + Number(FP2_num) > 0) {
                return ((Number(TP_num) / (Number(TP_num) + Number(FP2_num))) * 100).toFixed(
                    2
                );
            } else {
                return 0;
            }
        },
        PPV3_calc: function (TP_num, FP3_num) {
            if (Number(TP_num) + Number(FP3_num) > 0) {
                return ((Number(TP_num) / (Number(TP_num) + Number(FP3_num))) * 100).toFixed(
                    2
                );
            } else {
                return 0;
            }
        },
        sensitivity_calc: function (TP_num, FN_num) {
            if (Number(TP_num) + Number(FN_num) > 0) {
                return ((Number(TP_num) / (Number(TP_num) + Number(FN_num))) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        specificity_calc: function (TN_num, FP1_num) {
            if (Number(FP1_num) + Number(TN_num) > 0) {
                return ((Number(TN_num) / (Number(FP1_num) + Number(TN_num))) * 100).toFixed(
                    2
                );
            } else {
                return 0;
            }
        },
        cancer_detection_rate_calc: function (TP_num, TN_num, FP_num, FN_num) {
            if (Number(TP_num) + Number(TN_num) + Number(FP_num) + Number(FN_num) > 0) {
                return (
                    (Number(TP_num) /
                        (Number(TP_num) + Number(TN_num) + Number(FP_num) + Number(FN_num))) *
                    1000
                ).toFixed(2);
            } else {
                return 0;
            }
        },
        recall_rate_calc: function (TP_num, TN_num, FP_num, FN_num) {
            if (Number(TP_num) + Number(TN_num) + Number(FP_num) + Number(FN_num) > 0) {
                return (
                    ((Number(TP_num) + Number(FP_num)) /
                        (Number(TP_num) + Number(TN_num) + Number(FP_num) + Number(FN_num))) *
                    100
                ).toFixed(2);
            } else {
                return 0;
            }
        },
    },
};
</script>

<style scoped>
.fmo-audits-search-container {
    height: 850px;
    width: 100%;
    padding: 10px;
    margin: 10px;
    background: #1e2833;
}

.search-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: small;
}

.search-filters-dates {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.fmo-audits-list table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.fmo-audits-list table thead {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: left;
}

.fmo-audits-list table td,
.fmo-audits-list table thead th {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: smaller;
    text-align: center;
}

.fmo-audits-list table thead th {
    cursor: pointer;
    background: #000;
    padding: 7px;
    color: #5af;
    font-size: small;
}

.fmo-audits-list table thead th:hover {
    color: #5cf;
}

.fmo-audits-list table tr:nth-child(odd):not(.noBackground) {
    background: #151515;
}

.fmo-audits-list table tr:nth-child(even):not(.noBackground) {
    background: #202020;
}

.sort_none {
    width: 10px;
}

.sort_up {
    margin-left: 5px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.sort_down {
    margin-left: 5px;
    margin-bottom: 3px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.filters-header {
    font-size: 16px;
    color: #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;
    text-align: center;
}

.pagination-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pagination-section {
    width: 150px;
    margin: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pagination-section input[type="number"] {
    height: 30px;
    width: 30px;
    font-size: 18px;
    background: #000;
    font-size: medium;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    font-size: 16px;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    text-align: center;
}

.styled-button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    text-align: center;
    background: #214a80;
    padding: 10px;
    color: #fff;
    font-size: 18px;
}

.styled-button:hover {
    opacity: 0.8;
}

.styled-button:active {
    transform: translateY(1px);
}

.styled-button:focus {
    outline: none;
}

.styled-button::-moz-focus-inner {
    border: 0;
}

.pagination-button {
    background: #111;
    color: #fff;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import config from "./config.js";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: "bra-comm",
    storage: window.localStorage,
    //reducer: (state) => ({ authToken: state.authToken }), //only save authToken
    //filter: (mutation) => mutation.type == 'addNavItem'
});

const initialState = () => ({
    authToken: "",
    userName: "",
    site_view_mode: "",
    permissions: [],
    name: "",
    email: "",
    userId: "",
    phone: "",
    altPhone: "",
    location: "",
    statusText: "",
    displayRocketFrame: false,
    loginForm: {
        message: "",
        isError: false,
    },
    users: [],
    user_directory: [],
    user_directory_locations: [],
    recent_messages: {},
    video_chat_requests: {},
    text_chat_windows: [],
    text_chat_window_next_xy: 10,
    peer_reviews: [],
    qc_metrics: [],
    fmo_audits: [],
    phone_lists: [],
});

//export default new Vuex.Store({
const store = new Vuex.Store({
    state: initialState,
    mutations: {
        setAuthToken(state, token) {
            state.authToken = token;
        },
        setUserName(state, name) {
            state.userName = name;
        },
        setSiteViewMode(state, new_mode) {
            state.site_view_mode = new_mode;
        },
        setPermissions(state, perm_array) {
            state.permissions = perm_array;
        },
        setName(state, name) {
            state.name = name;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setUserId(state, id) {
            state.userId = id;
        },
        setPhone(state, number) {
            state.phone = number;
        },
        setAltPhone(state, number) {
            state.altPhone = number;
        },
        setLocation(state, loc) {
            state.location = loc;
        },
        setStatusText(state, status) {
            state.statusText = status;
        },
        setDisplayRocketFrame(state, isDisplayed) {
            state.displayRocketFrame = isDisplayed;
        },
        setLoginFormMessage(state, payload) {
            state.loginForm.message = payload.message;
            state.loginForm.isError = payload.error;
        },
        setUsers(state, userlist) {
            state.users = userlist;
        },
        setUserDirectory(state, userlist) {
            state.user_directory = userlist;
        },
        setUserDirectoryLocations(state, location_list) {
            state.user_directory_locations = location_list;
        },
        setRecentMessages(state, message_list) {
            state.recent_messages = message_list;
        },
        setVideoChatRequests(state, requests) {
            state.video_chat_requests = requests;
        },
        setPeerReviews(state, peer_review_list) {
            state.peer_reviews = peer_review_list;
        },
        setQCMetrics(state, qc_metrics_list) {
            state.qc_metrics = qc_metrics_list;
        },
        setFMOAudits(state, fmo_audits_list) {
            state.fmo_audits = fmo_audits_list;
        },
        setPhoneLists(state, phone_lists_list) {
            state.phone_lists = phone_lists_list;
        },
        addTextChatWindow(state, payload) {
            if (!Array.isArray(state.text_chat_windows)) {
                state.text_chat_windows = [];
            }
            if (
                !state.text_chat_windows.some(
                    (text_window) => text_window.username === payload.username
                )
            ) {
                state.text_chat_windows.push(payload);
                if (state.text_chat_window_next_xy < 100) {
                    state.text_chat_window_next_xy += 10;
                } else {
                    state.text_chat_window_next_xy = 10;
                }
            }
        },
        removeTextChatWindow(state, username) {
            if (Array.isArray(state.text_chat_windows)) {
                state.text_chat_windows = state.text_chat_windows.filter(function (
                    text_window
                ) {
                    return text_window.username !== this;
                },
                username);
            } else {
                state.text_chat_windows = [];
            }
        },
        openTextChatWindow(state, username) {
            let objIndex = state.text_chat_windows.findIndex(
                (obj) => obj.username == username
            );
            if (objIndex !== -1) {
                state.text_chat_windows[objIndex].is_visible = true;
            }
        },
        resetState(state) {
            const initial = initialState();
            Object.keys(initial).forEach((key) => {
                state[key] = initial[key];
            });
        },
    },
    getters: {
        isAuthenticated: (state) => {
            return state.authToken !== "";
        },
        isRadiologist: (state) => {
            if (state.site_view_mode == "tech") {
                return false;
            } else {
                return (
                    config.radiologist_list.includes(state.userName) ||
                    state.site_view_mode == "rad"
                );
            }
        },
        hasPermission: (state) => (permission_item) => {
            if (permission_item == "super_admin") {
                return state.permissions.includes("super_admin");
            } else {
                return (
                    state.permissions.includes(permission_item) ||
                    state.permissions.includes("admin")
                );
            }
        },
        getRadiologists: (state) => {
            return state.users.filter(function (user) {
                return config.radiologist_list.includes(user.username);
            });
        },
        getTechs: (state) => {
            return state.users.filter(function (user) {
                return !config.radiologist_list.includes(user.username);
            });
        },
        getUnreadCount: (state) => {
            let total = 0;
            for (let message of Object.values(state.recent_messages)) {
                if (message.read_status == 0) {
                    total++;
                }
            }
            return total;
        },
        getTextChatWindowByUsername: (state) => (username) => {
            return state.text_chat_windows.find(
                (chat_window) => chat_window.username === username
            );
        },
        getUserObjectById: (state) => (user_id) => {
            let user_obj = state.users.find(function (user) {
                return user._id == user_id;
            });
            return user_obj;
        },
    },
    actions: {
        initUser(state) {
            // Going to put stuff here that should get set when a user logs in
            if (config.radiologist_list.includes(state.state.userName)) {
                state.commit("setSiteViewMode", "rad");
            } else {
                state.commit("setSiteViewMode", "tech");
            }
        },
        refreshUsers(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/users-list",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (response.data.success) {
                    response.data.users.forEach(function (user) {
                        if (typeof user.emails !== "undefined") {
                            if (typeof user.emails[0].address !== "undefined") {
                                user.email = user.emails[0].address;
                            } else {
                                user.email = "";
                            }
                        }
                    });
                    state.commit("setUsers", response.data.users);
                }
            });
        },
        fetchPermissions(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/user-permissions",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (response.data.success) {
                    state.commit("setPermissions", response.data.permissions);
                }
            });
        },
        fetchPeerReviews(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/peer-review-select-all",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (typeof response.data.results !== "undefined") {
                    state.commit("setPeerReviews", response.data.results);
                }
            });
        },
        fetchQCMetrics(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/qc-metrics-select-all",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (typeof response.data.results !== "undefined") {
                    state.commit("setQCMetrics", response.data.results);
                }
            });
        },
        fetchFMOAudits(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/fmo-audit-select-all",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (typeof response.data.results !== "undefined") {
                    state.commit("setFMOAudits", response.data.results);
                }
            });
        },
        fetchPhoneLists(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/phone-list-view",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (typeof response.data.results !== "undefined") {
                    state.commit("setPhoneLists", response.data.results);
                }
            });
        },
        fetchUserDirectory(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/users/list",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            }).then((response) => {
                if (typeof response.data.result !== "undefined") {
                    state.commit("setUserDirectory", response.data.result);
                }
            });
        },
        fetchUserDirectoryLocations(state) {
            return new Promise((resolve) => {
                axios({
                    method: "post",
                    url: config.bra_api_url + "/locations/list",
                    data: {
                        userId: state.state.userId,
                        authToken: state.state.authToken,
                    },
                }).then((response) => {
                    if (typeof response.data.result !== "undefined") {
                        state.commit("setUserDirectoryLocations", response.data.result);
                        resolve();
                    }
                });
            })
        },
        markRoomRead(state, room_obj) {
            axios({
                method: "post",
                url: config.rc_api_url + "/api/v1/subscriptions.read",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": state.state.authToken,
                    "X-User-Id": state.state.userId,
                },
                data: {
                    rid: room_obj.room_id,
                },
            });
            axios({
                method: "post",
                url: config.bra_api_url + "/mark-room-read",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                    room_id: room_obj.room_id,
                },
            }).then(() => {
                this.dispatch("getRecentMessages");
            });
        },
        getVideoChatRequests(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/get-video-chat-requests",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                    to_user_username: state.state.userName,
                },
            }).then((response) => {
                if (response.data.success) {
                    let requests = {};
                    response.data.results.forEach(function (result) {
                        requests[result.from_user_username] = result;
                    });
                    state.commit("setVideoChatRequests", requests);
                }
            });
        },
        markVideoRequestInactive(state, request_obj) {
            axios({
                method: "post",
                url: config.bra_api_url + "/mark-video-request-inactive",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                    request_id: request_obj.request_id,
                },
            }).then(() => {
                this.dispatch("getVideoChatRequests");
            });
        },
        getRecentMessages(state) {
            axios({
                method: "post",
                url: config.bra_api_url + "/get-recent-messages",
                data: {
                    userId: state.state.userId,
                    authToken: state.state.authToken,
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        let messages = {};
                        response.data.results.forEach(function (result) {
                            let message_text = result.message_text;
                            if (typeof messages[result.room_id] !== "undefined") {
                                message_text =
                                    messages[result.room_id].message_text +
                                    "\n" +
                                    message_text;
                            }
                            messages[result.room_id] = {
                                read_status: result.read_status,
                                room_id: result.room_id,
                                from_user_userid: result.from_user_userid,
                                from_user_username: result.from_user_username,
                                from_user_fullname: result.from_user_fullname,
                                message_text: message_text,
                                date_insert: result.date_insert,
                            };
                        });
                        state.commit("setRecentMessages", messages);
                    }
                })
                .then(() => {
                    for (const message of Object.values(state.state.recent_messages)) {
                        let windowIndex = state.state.text_chat_windows.findIndex(
                            (obj) => obj.username == message.from_user_username
                        );
                        if (windowIndex == -1) {
                            let text_window = {
                                x: state.state.text_chat_window_next_xy,
                                y: state.state.text_chat_window_next_xy,
                                h: 500,
                                w: 500,
                                title: "Chat with " + message.from_user_fullname,
                                username: message.from_user_username,
                                start_open: true,
                                is_visible: false,
                                show_button: false,
                            };
                            state.commit("addTextChatWindow", text_window);
                        }
                    }
                });
        },
    },
    modules: {},
    plugins: [vuexLocal.plugin],
});

export default store;

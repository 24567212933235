<template>
    <div v-if="can_submit" class="qc-metrics-form-container">
        <div class="qc-metrics-form">
            <div class="form-header">
                <div class="form-header-title">QC Metrics Form</div>
            </div>
            <div class="form-content">
                <input type="text" v-model="accession" placeholder="Accession ID" />
                <div class="input-label">Modality:</div>
                <select class="select-css" v-model="modality">
                    <option v-for="modality in qc_metrics_modalities" v-bind:key="modality">
                        {{ modality }}
                    </option>
                </select>
                <div class="input-label">Location:</div>
                <select class="select-css" v-model="location">
                    <option v-for="loc in qc_metrics_locations" v-bind:key="loc">
                        {{ loc }}
                    </option>
                </select>
                <div class="input-label">Date of Metric:</div>
                <input type="date" v-model="date_of_metric" />
                <input type="time" v-model="time_of_metric" />
                <textarea v-model="comments" rows="5" placeholder="Comments"></textarea>
                <div class="submit-button-container">
                    <button class="styled-button submit-button" v-on:click="submitForm()">
                        Submit Review
                    </button>
                </div>
                <div class="success-message green" v-if="success_message !== ''">
                    {{ success_message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";
var today_date = new Date().toISOString().slice(0, 10);

export default {
    name: "QCMetricsForm",
    data() {
        return {
            modality: "RAD",
            accession: "",
            comments: "",
            location: "",
            date_of_metric: today_date,
            time_of_metric: "08:00",
            success_message: "",
        };
    },
    mounted() {
        this.$nextTick(function () {
            this.modality = this.qc_metrics_modalities[0];
            this.location = this.qc_metrics_locations[0];
        });
    },
    computed: {
        can_submit() {
            return this.$store.getters.hasPermission("qc_metrics_submit");
        },
        form_entry_username() {
            return this.$store.state.userName;
        },
        qc_metrics_locations() {
            return config.location_list;
        },
        qc_metrics_modalities() {
            return config.modalities_list;
        },
    },
    methods: {
        submitForm() {
            this.success_message = "";
            axios({
                method: "post",
                url: config.bra_api_url + "/qc-metrics-submit",
                data: {
                    userId: this.$store.state.userId,
                    authToken: this.$store.state.authToken,
                    qc_metric_obj: {
                        modality: this.modality,
                        accession: this.accession,
                        location: this.location,
                        comments: this.comments,
                        date_of_metric: this.date_of_metric + " " + this.time_of_metric,
                    },
                },
            }).then((result) => {
                if (result.data.success) {
                    this.success_message =
                        "Added QC Metric: " +
                        result.data.qc_metric_obj.accession +
                        " - " +
                        result.data.qc_metric_obj.modality +
                        " - " +
                        result.data.qc_metric_obj.location;
                }
                this.$store.dispatch("fetchQCMetrics");
            });
        },
    },
};
</script>

<style scoped>
.qc-metrics-form-container {
    margin: 10px;
}

.form-header {
    color: #fff;
    background: #151515;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid #999;
    border-left: 2px solid #999;
    border-right: 2px solid #999;
    border-bottom: 2px solid #999;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 420px;
}

.form-header-title {
    padding: 10px;
    font-size: large;
    padding-left: 20px;
}

.form-content {
    width: 400px;
    background: #1e2833;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    border-left: 2px solid #999;
}

.form-header-close {
    cursor: pointer;
    padding: 10px;
    font-size: large;
    border-top-right-radius: 10px;
    border-left: 1px solid #999;
}

.form-header-close:hover {
    background: rgba(255, 255, 255, 0.2);
}

.success-message {
    font-size: 12px;
    text-align: center;
}

.green {
    color: #00ff99;
}

input[type="text"] {
    background: none;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: large;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    width: 90%;
}

.input-label {
    margin-top: 15px;
    font-size: medium;
    padding-bottom: 2px;
    color: #999;
}

.styled-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 14px;
}

.styled-button:hover {
    opacity: 0.8;
}

.styled-button:active {
    transform: translateY(1px);
}

.styled-button:focus {
    outline: none;
}

.styled-button::-moz-focus-inner {
    border: 0;
}

.submit-button-container {
    width: 90%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.submit-button {
    padding: 10px;
    font-size: large;
}

textarea {
    width: 350px;
    background: #111;
    color: #999;
    margin-top: 20px;
    border-radius: 4px;
    padding: 7px;
}

textarea:focus {
    outline: none !important;
    border: 1px solid #5af;
}

.select-css {
    display: block;
    font-size: small;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}

.select-css option {
    font-weight: normal;
}
</style>

<template>
    <div class="profile">
        <ProfileEditForm />
        <div class="logout-from-all">
            <LogoutFromAll />
        </div>
    </div>
</template>

<script>
import ProfileEditForm from "@/components/ProfileEditForm.vue";
import LogoutFromAll from "@/components/LogoutFromAll.vue";

export default {
    name: "profile",
    components: {
        ProfileEditForm,
        LogoutFromAll,
    },
};
</script>

<style scoped>
.profile {
    text-align: left;
    display: inline-block;
    width: 100%;
}

.logout-from-all {
    font-size: large;
    margin: 10px;
    padding: 20px;
    border-radius: 4px;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router.js";
import store from "./store.js";
import config from "./config.js";

const axiosInstance = axios.create({
    baseURL: "https://chat.bra-communicator.com",
});

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return error;
    }
);

Vue.prototype.appConfig = config;
Vue.prototype.axios = axiosInstance;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

<template>
    <div></div>
</template>

<script>
export default {
    name: "Files",
};
</script>

<style>
#NextCloudFrame {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

#NextCloudFrame iframe {
    width: 100%;
    height: calc(100vh - 125px);
    border: none;
}
</style>

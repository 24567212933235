<template>
    <div class="radoncall-container">
        <div class="radoncall">
            <div class="set-rad-on-call" v-if="this.$store.getters.isRadiologist">
                <SetRadOnCall />
            </div>
            <div class="rad-header">Radiologist on Call:</div>
            <div v-if="this.$store.getters.isAuthenticated">
                <UserList
                    class="radiologist-list"
                    v-bind:show-header="false"
                    v-bind:show-title="false"
                    v-bind:no-background="true"
                    v-bind:max-results="1"
                    usertype="radiologists"
                    v-bind:rad-on-call-list="true"
                    v-bind:fields="['name', 'statusText', 'phone']"
                    v-bind:show-text-chat-button="true"
                    v-bind:show-video-chat-button="false"
                >
                </UserList>
            </div>
            <div class="logged-out-rad-on-call" v-if="!this.$store.getters.isAuthenticated">
                {{ logged_out_rad_on_call }}
                <div v-bind:class="statusColor">
                    {{ logged_out_rad_on_call_status }}
                </div>
            </div>
        </div>
        <div class="radonIDI">
            <div class="set-rad-on-idi" v-if="this.$store.getters.isRadiologist">
                <SetRadOnIDI />
            </div>
            <div class="rad-header">Radiologist on IDI:</div>
            <div v-if="this.$store.getters.isAuthenticated">
                <UserList
                    class="radiologist-list-idi"
                    v-bind:show-header="false"
                    v-bind:show-title="false"
                    v-bind:no-background="true"
                    v-bind:max-results="1"
                    usertype="radiologists"
                    v-bind:rad-on-i-d-i="true"
                    v-bind:fields="['name', 'statusText', 'phone']"
                    v-bind:show-text-chat-button="true"
                    v-bind:show-video-chat-button="false"
                >
                </UserList>
            </div>
            <div class="logged-out-rad-on-idi" v-if="!this.$store.getters.isAuthenticated">
                {{ logged_out_rad_on_idi }}
                <div v-bind:class="statusColorIDI">
                    {{ logged_out_rad_on_idi_status }}
                </div>
            </div>
        </div>
        <div class="logged-out-message" v-if="!this.$store.getters.isAuthenticated">
            (Log in to view radiologist contact information)
        </div>
    </div>
</template>

<script>
import UserList from "@/components/UserList.vue";
import SetRadOnCall from "@/components/SetRadOnCall.vue";
import SetRadOnIDI from "@/components/SetRadOnIDI.vue";
import config from "@/config.js";
import axios from "axios";

export default {
    name: "RadOnCall",
    components: {
        UserList,
        SetRadOnCall,
        SetRadOnIDI,
    },
    data() {
        return {
            timer: "",
            timer_interval: 30000,
            logged_out_rad_on_call: "",
            logged_out_rad_on_call_status: "",
            logged_out_rad_on_idi: "",
            logged_out_rad_on_idi_status: ""
        };
    },
    created() {
        this.getRadOnCall();
        this.getRadOnIDI();
        this.timer = setInterval(() => {
            this.getRadOnCall();
            this.getRadOnIDI();
        }, this.timer_interval);
    },
    computed: {
        statusColor() {
            if (
                this.logged_out_rad_on_call_status == "At the Hospital - Reading All Studies"
            ) {
                return "green";
            } else if (
                this.logged_out_rad_on_call_status ==
                "Not at the Hospital - Reading CT/MR/US/NM Only"
            ) {
                return "yellow";
            } else {
                return "red";
            }
        },
        statusColorIDI() {
            if (
                this.logged_out_rad_on_idi_status == "Reading - Institute of Diagnostic Imaging" ||
                this.logged_out_rad_on_idi_status == "Reading - IDI"
            ) {
                return "green";
            } else {
                return "red";
            }
        },
    },
    methods: {
        getRadOnCall() {
            if (this.$store.getters.isAuthenticated) {
                this.logged_out_rad_on_call = "";
                this.logged_out_rad_on_call_status = "";
            } else {
                axios({
                    method: "post",
                    url: config.bra_api_url + "/rad-on-call",
                }).then((resp) => {
                    if (resp.data.success) {
                        this.logged_out_rad_on_call = resp.data.name;
                        this.logged_out_rad_on_call_status = resp.data.statusText;
                    } else {
                        this.logged_out_rad_on_call = "N/A";
                        this.logged_out_rad_on_call_status = "No Radiologist On Call";
                    }
                });
            }
        },
        getRadOnIDI() {
            if (this.$store.getters.isAuthenticated) {
                this.logged_out_rad_on_idi = "";
                this.logged_out_rad_on_idi_status = "";
            } else {
                axios({
                    method: "post",
                    url: config.bra_api_url + "/rad-on-idi",
                }).then((resp) => {
                    if (resp.data.success) {
                        this.logged_out_rad_on_idi = resp.data.name;
                        this.logged_out_rad_on_idi_status = resp.data.statusText;
                    } else {
                        this.logged_out_rad_on_idi = "";
                        this.logged_out_rad_on_idi_status = "No Radiologist Reading IDI";
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
.radoncall-container {
    background: #000;
    color: #dddddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: auto;
    padding: 10px;
}
.radoncall, .radonIDI {
    min-height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.green {
    color: #00ff99;
}

.yellow {
    color: #f1f35f;
}

.red {
    color: #e04545;
}
.logged-out-rad-on-call, .logged-out-rad-on-idi {
    padding-left: 10px;
    font-size: 18px;
}

.logged-out-rad-on-call div, .logged-out-rad-on-idi div {
    display: inline-block;
    padding-left: 15px;
}

.rad-header {
    font-size: 16px;
    font-weight: bold;
    padding-right: 5px;
}

.set-rad-on-call, .set-rad-on-idi {
    padding-right: 15px;
}

.radiologist-list table tr td {
    font-size: 16px;
}

.logged-out-message{
    padding-top: 5px;
    font-size: 14px;
    font-style:italic;
}
</style>

<template>
    <div v-if="can_view" class="user-directory-search-container">
        <div class="user-search-area">
            <div class="search-filters">
                <div class="search-filter-group">
                    <div class="filters-header">
                        Included Locations
                        <span class="span-link" @click="checkAllLocations">Check All</span>
                        <span class="span-link" @click="unCheckAllLocations">Uncheck All</span>
                    </div>
                    <div class="search-filter-locations">
                        <div
                            v-for="location in user_directory_locations"
                            v-bind:key="location.id"
                        >
                            <input
                                type="checkbox"
                                v-bind:value="location.id"
                                v-model="checkedLocations"
                            />
                            {{ location.location_name }}
                        </div>
                    </div>
                </div>
                <div class="search-filter-group search-filter-text">
                    <input
                        type="text"
                        class="text-input"
                        v-model="searchText"
                        placeholder="Search..."
                    />
                    <div class="note">
                        Searches username, full name, email, and phone columns.
                    </div>
                </div>
                <div class="pagination-section-container search-filter-group">
                    <button class="styled-button" @click="csvExport()">Export to CSV</button>
                    <div class="pagination-section">
                        <button class="styled-button pagination-button" @click="prevPage">
                            &lt;
                        </button>
                        <input
                            type="number"
                            v-model="currentPage"
                            min="1"
                            v-bind:max="pages_max"
                        />
                        of
                        {{ pages_max }}
                        <button class="styled-button pagination-button" @click="nextPage">
                            &gt;
                        </button>
                    </div>
                </div>
            </div>
            <div class="user-list">
                <table>
                    <thead>
                        <th @click="sort('id')">
                            ID
                            <span v-bind:class="getAscDescClass('id')"></span>
                        </th>
                        <th @click="sort('fullname')">
                            Full Name
                            <span v-bind:class="getAscDescClass('fullname')"></span>
                        </th>
                        <th @click="sort('email')">
                            Email
                            <span v-bind:class="getAscDescClass('email')"></span>
                        </th>
                        <th @click="sort('phone')">
                            Phone
                            <span v-bind:class="getAscDescClass('phone')"></span>
                        </th>
                        <th @click="sort('location_name')">
                            Location
                            <span v-bind:class="getAscDescClass('location')"></span>
                        </th>
                        <th></th>
                    </thead>
                    <tr v-for="user in sortedUsers" v-bind:key="user.id">
                        <td>{{ user.id }}</td>
                        <td>{{ user.fullname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phone }}</td>
                        <td>{{ user.location_name }}</td>
                        <td>
                            <span class="span-link" @click="setViewMoreUserId(user.id)"
                                >More Info</span
                            >
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="user-view-area">
            <UserDirectoryUserView v-bind:userid="view_more_user_id" />
        </div>
    </div>
</template>

<script>
import UserDirectoryUserView from "@/components/UserDirectoryUserView.vue";

export default {
    name: "UserDirectorySearch",
    components: {
        UserDirectoryUserView,
    },
    data() {
        return {
            currentSort: "id",
            currentSortDir: "desc",
            searchText: "",
            pageSize: 20,
            currentPage: 1,
            checkedLocations: [],
            view_more_user_id: 0,
        };
    },
    created() {
        this.$store.dispatch("fetchUserDirectory");
        this.$store.dispatch("fetchUserDirectoryLocations").then(() => {
            this.checkAllLocations();
        });
    },
    computed: {
        can_view() {
            return this.$store.getters.hasPermission("view_user_info");
        },
        user_directory() {
            var result = this.$store.state.user_directory.map(function (user) {
                var obj = Object.assign({}, user);
                obj.location_name = this.getLocationNameById(obj.location_id);
                return obj;
            }, this);
            return result;
        },
        user_directory_locations() {
            return this.$store.state.user_directory_locations;
        },
        filtered_users() {
            let user_directory_copy = [...this.user_directory];
            user_directory_copy = user_directory_copy.filter((user) => {
                return this.checkedLocations.includes(user.location_id);
            });

            if (this.searchText !== "") {
                user_directory_copy = user_directory_copy.filter((user) => {
                    return (
                        user.username.toLowerCase().includes(this.searchText.toLowerCase()) ||
                        user.fullname.toLowerCase().includes(this.searchText.toLowerCase()) ||
                        user.email.toLowerCase().includes(this.searchText.toLowerCase()) ||
                        user.phone.includes(this.searchText)
                    );
                });
            }
            return user_directory_copy;
        },
        pages_max() {
            return Math.ceil(this.filtered_users.length / this.pageSize);
        },
        sortedUsers: function () {
            return [...this.filtered_users]
                .sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === "desc") modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                })
                .filter((row, index) => {
                    let start = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    if (index >= start && index < end) return true;
                });
        },
    },
    methods: {
        setViewMoreUserId: function (id) {
            this.view_more_user_id = id;
        },
        getLocationNameById: function (id) {
            let location = this.user_directory_locations.find((location) => location.id == id);
            return location.location_name;
        },
        checkAllLocations: function () {
            this.checkedLocations = this.user_directory_locations.map(function (i) {
                return i.id;
            });
        },
        unCheckAllLocations: function () {
            this.checkedLocations = [];
        },
        sort: function (s) {
            //if s == current sort, reverse
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
            }
            this.currentSort = s;
        },
        nextPage: function () {
            if (this.currentPage * this.pageSize < this.filtered_users.length)
                this.currentPage++;
        },
        prevPage: function () {
            if (this.currentPage > 1) this.currentPage--;
        },
        getAscDescClass: function (col_name) {
            if (this.currentSort == col_name) {
                if (this.currentSortDir == "asc") {
                    return "sort_up";
                } else {
                    return "sort_down";
                }
            } else {
                return "sort_none";
            }
        },
        csvExport() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(this.filtered_users[0]).join(","),
                ...this.filtered_users.map((item) =>
                    Object.values(item)
                        .map((inner_item) => {
                            return '"' + inner_item + '"';
                        })
                        .join(",")
                ),
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("target", "_blank");
            link.setAttribute("download", "bra_user_directory.csv");
            link.click();
        },
    },
};
</script>

<style scoped>
.user-directory-search-container {
    height: 750px;
    width: calc(100vw - 40px);
    padding: 10px;
    margin: 10px;
    background: #1e2833;
    display: flex;
    flex-direction: row;
}

.user-view-area {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #ccc;
}

.user-search-area {
    padding: 10px;
}

.search-filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.search-filter-group {
    padding-left: 20px;
}

.search-filter-text {
    padding-top: 20px;
}

.search-filter-locations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 150px;
    font-size: 12px;
}

.user-list table {
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;
}

.user-list table thead {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: center;
}

.user-list table td,
.user-list table thead th {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.user-list table thead th {
    cursor: pointer;
    background: #000;
    padding: 7px;
    color: #5af;
}

.user-list table thead th:hover {
    color: #5cf;
}

.user-list table tr:nth-child(odd):not(.noBackground) {
    background: #151515;
}

.user-list table tr:nth-child(even):not(.noBackground) {
    background: #202020;
}

.sort_none {
    width: 10px;
}

.sort_up {
    margin-left: 5px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.sort_down {
    margin-left: 5px;
    margin-bottom: 3px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.filters-header {
    font-size: 16px;
    color: #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: 5px;
    text-align: center;
}

.span-link {
    font-size: 12px;
    color: #5af;
    padding-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}

.span-link:hover {
    color: #5cf;
}

.pagination-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pagination-section {
    width: 150px;
    margin: 15px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.pagination-section input[type="number"] {
    height: 30px;
    width: 30px;
    font-size: 18px;
    background: #000;
    font-size: medium;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    font-size: 16px;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    text-align: center;
}

.styled-button {
    cursor: pointer;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    text-align: center;
    background: #214a80;
    padding: 10px;
    color: #fff;
    font-size: 18px;
}

.styled-button:hover {
    opacity: 0.8;
}

.styled-button:active {
    transform: translateY(1px);
}

.styled-button:focus {
    outline: none;
}

.styled-button::-moz-focus-inner {
    border: 0;
}

.pagination-button {
    background: #111;
    color: #fff;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.text-input {
    background: #000;
    padding: 10px 5px;
    margin: 8px 0;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    min-width: 300px;
}

.note {
    font-size: 12px;
    color: #aaa;
}
</style>

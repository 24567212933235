<template>
    <div class="component-container">
        <div
            class="alert-message"
            v-show="alert_message !== ''"
            v-bind:class="{ error: is_error }"
        >
            {{ alert_message }}
        </div>
        <div class="user-profile">
            <a href="#" class="back-link" v-show="editing_info" v-on:click="clearForm()"
                >&lt; back</a
            >
            <div class="user-profile-header">{{ header_text }}</div>
            <div class="user-profile-header-message" v-show="header_message !== ''">
                <div>{{ header_message }}</div>
            </div>
            <div class="user-field-list" v-show="!editing_info">
                <div class="user-field user-name">
                    <div class="field-label">Name:</div>
                    <div class="field-item">{{ name }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editName()">edit</a>
                </div>
                <div class="user-field user-username">
                    <div class="field-label">Username:</div>
                    <div class="field-item">{{ username }}</div>
                    <a
                        href="#"
                        class="edit-field-link"
                        v-if="!this.$store.getters.isRadiologist"
                        v-on:click="editUsername()"
                        >edit</a
                    >
                </div>
                <div class="user-field user-email">
                    <div class="field-label">Email:</div>
                    <div class="field-item">{{ email }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editEmail()">edit</a>
                </div>
                <div class="user-field user-phone">
                    <div class="field-label">Phone:</div>
                    <div class="field-item">{{ phone }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editPhone()">edit</a>
                </div>
                <div class="user-field user-altphone">
                    <div class="field-label">Alt. Phone:</div>
                    <div class="field-item">{{ altphone }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editAltPhone()">edit</a>
                </div>
                <div class="user-field user-statusText">
                    <div class="field-label">StatusText:</div>
                    <div class="field-item">{{ statusText }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editStatusText()">edit</a>
                </div>
                <div class="user-field user-location">
                    <div class="field-label">Location:</div>
                    <div class="field-item">{{ location }}</div>
                    <a href="#" class="edit-field-link" v-on:click="editLocation()">edit</a>
                </div>
                <div class="button-container">
                    <a href="#" class="update-password-link" v-on:click="updatePassword()"
                        >Update Password</a
                    >
                </div>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'name'">
                <form @submit.prevent="submitName">
                    <div class="user-field">
                        <div class="field-label">Current Name:</div>
                        <div class="field-item">{{ name }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Name:</div>
                        <input
                            class="text-input"
                            type="text"
                            v-model="input.name"
                            placeholder="Full Name"
                            required
                            autocomplete="on"
                        />
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'username'">
                <div class="username-message">
                    <div>
                        <div>Usernames may only contain:</div>
                        <div class="indent">- upper and lowercase letters (a-z, A-Z)</div>
                        <div class="indent">- Numbers (0-9)</div>
                        <div class="indent">
                            - Periods (.), Dashes (-), and Underscores (_)
                        </div>
                        <div>Usernames must start with letter.</div>
                        <div>Usernames must end with a letter or number.</div>
                    </div>
                </div>
                <form @submit.prevent="submitUsername">
                    <div class="user-field">
                        <div class="field-label">Current Username:</div>
                        <div class="field-item">{{ username }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Username:</div>
                        <input
                            class="text-input"
                            type="text"
                            pattern="[a-zA-Z][a-zA-Z0-9-_.]*[a-zA-Z0-9]+"
                            v-model="input.username"
                            placeholder="Username"
                            required
                            autocomplete="on"
                        />
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'email'">
                <form @submit.prevent="submitEmail">
                    <div class="user-field">
                        <div class="field-label">Current Email:</div>
                        <div class="field-item">{{ email }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Email:</div>
                        <input
                            class="text-input"
                            type="email"
                            v-model="input.email"
                            placeholder="Email"
                            required
                            autocomplete="on"
                        />
                    </div>
                    <div class="user-field">
                        <div class="field-label">Password:</div>
                        <input
                            class="text-input"
                            type="password"
                            v-model="input.password"
                            placeholder="Password"
                            required
                        />
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'phone'">
                <form @submit.prevent="submitPhone">
                    <div class="user-field">
                        <div class="field-label">Current Phone:</div>
                        <div class="field-item">{{ phone }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Phone:</div>
                        <div>
                            <input
                                class="text-input"
                                type="tel"
                                v-model="input.phone"
                                placeholder="Phone"
                                required
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                autocomplete="on"
                            />
                            <div class="phone-format">Format: 123-456-7890</div>
                        </div>
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'altphone'">
                <form @submit.prevent="submitAltPhone">
                    <div class="user-field">
                        <div class="field-label">Current Alt. Phone:</div>
                        <div class="field-item">{{ altphone }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Alt. Phone:</div>
                        <div>
                            <input
                                class="text-input"
                                type="tel"
                                v-model="input.altphone"
                                placeholder="Phone"
                                required
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                autocomplete="on"
                            />
                            <div class="phone-format">Format: 123-456-7890</div>
                        </div>
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'statustext'">
                <form @submit.prevent="submitStatusText">
                    <div class="user-field">
                        <div class="field-label">Current Status Text:</div>
                        <div class="field-item">{{ statusText }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Status Text:</div>
                        <input
                            class="text-input"
                            type="text"
                            v-model="input.statusText"
                            placeholder="Status Text"
                            autocomplete="on"
                        />
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'statustext_rad'">
                <form @submit.prevent="submitStatusText">
                    <div class="user-field">
                        <div class="field-label">Current Status Text:</div>
                        <div class="field-item">{{ statusText }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Status Text:</div>
                        <select class="select-css" v-model="input.statusText">
                            <option :value="null" disabled>Status Message</option>
                            <option>At the Hospital - Reading All Studies</option>
                            <option>Not at the Hospital - Reading CT/MR/US/NM Only</option>
                            <option>NOT CURRENTLY READING - PLEASE SEND TO VRAD</option>
                            <option>Offline</option>
                        </select>
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'location'">
                <form @submit.prevent="submitLocation">
                    <div class="user-field">
                        <div class="field-label">Current Location:</div>
                        <div class="field-item">{{ location }}</div>
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Location:</div>
                        <div>
                            <select class="select-css" v-model="input.location">
                                <option :value="null" disabled>Location</option>
                                <option>CHI St. Alexius Bismarck</option>
                                <option>CHI Minot</option>
                                <option>CHI Mandan</option>
                                <option>CHI Garrison</option>
                                <option>CHI Turtle Lake</option>
                                <option>Ashley</option>
                                <option>Bowman</option>
                                <option>Elgin</option>
                                <option>Harvey</option>
                                <option>Hettinger</option>
                                <option>Linton</option>
                                <option>Sakakawea/Hazen</option>
                                <option>Wishek</option>
                            </select>
                        </div>
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div class="user-edit-form" v-if="editing_info == 'password'">
                <form @submit.prevent="submitUpdatePassword">
                    <div class="user-field">
                        <div class="field-label">Current Password:</div>
                        <input
                            class="text-input"
                            type="password"
                            v-model="input.password"
                            placeholder="Current Password"
                            required
                        />
                    </div>
                    <div class="user-field">
                        <div class="field-label">New Password:</div>
                        <input
                            class="text-input"
                            type="password"
                            v-model="input.new_password"
                            placeholder="New Password"
                            required
                        />
                    </div>
                    <div class="user-field">
                        <div class="field-label">Confirm Password:</div>
                        <input
                            class="text-input"
                            type="password"
                            v-model="input.new_password_confirm"
                            placeholder="Confirm New Password"
                            required
                        />
                    </div>
                    <div class="button-container">
                        <button class="edit-button" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
var shajs = require("sha.js");
export default {
    name: "ProfileEditForm",
    data() {
        return {
            alert_message: "",
            is_error: false,
            header_message: "",
            initial_header: "Profile Information",
            header_text: "",
            editing_info: false,
            name: "",
            username: "",
            email: "",
            password: "",
            password_confirm: "",
            phone: "",
            altphone: "",
            statusText: "",
            location: null,
            edit_user: false,
            input: {
                name: "",
                username: "",
                email: "",
                password: "",
                new_password: "",
                new_password_confirm: "",
                phone: "",
                altphone: "",
                statusText: "",
                location: null,
            },
        };
    },
    created: function () {
        this.header_text = this.initial_header;
        this.axios({
            method: "get",
            url: "/api/v1/me",
            headers: {
                "Content-Type": "application/json",
                "X-Auth-Token": this.$store.state.authToken,
                "X-User-Id": this.$store.state.userId,
            },
            data: {},
        }).then((response) => {
            if (response.data.success) {
                this.name = response.data.name;
                this.username = response.data.username;
                this.email = response.data.emails[0].address;
                this.phone = response.data.customFields.phone;
                this.altphone = response.data.customFields.altphone;
                this.location = response.data.customFields.location;
                this.statusText = response.data.statusText;
                this.$store.commit("setName", response.data.name);
                this.$store.commit("setUserName", response.data.username);
                this.$store.commit("setEmail", response.data.emails[0].address);
                this.$store.commit("setPhone", response.data.customFields.phone);
                this.$store.commit("setAltPhone", response.data.customFields.altphone);
                this.$store.commit("setLocation", response.data.customFields.location);
                this.$store.commit("setStatusText", response.data.statusText);
                this.input.name = this.name;
                this.input.username = this.username;
                this.input.email = this.email;
                this.input.phone = this.phone;
                this.input.altphone = this.altphone;
                this.input.location = this.location;
                this.input.statusText = this.statusText;
            }
        });
    },
    methods: {
        clearForm() {
            this.is_error = false;
            this.header_text = this.initial_header;
            this.alert_message = "";
            this.header_message = "";
            this.editing_info = false;
        },
        editName() {
            this.header_text = "Edit Name";
            this.editing_info = "name";
        },
        submitName() {
            if (this.input.name !== "") {
                this.handleSubmit({ data: { name: this.input.name } }, (response) => {
                    this.name = response.data.user.name;
                    this.$store.commit("setName", response.data.user.name);
                    this.alert_message = "Name updated successfully.";
                });
            }
        },
        editUsername() {
            this.header_text = "Edit Username";
            this.editing_info = "username";
        },
        submitUsername() {
            if (this.input.username !== "") {
                this.handleSubmit({ data: { username: this.input.username } }, (response) => {
                    this.username = response.data.user.username;
                    this.$store.commit("setUserName", response.data.user.username);
                    this.alert_message = "Username updated successfully.";
                });
            }
        },
        editPhone() {
            this.header_text = "Edit Phone Number";
            this.editing_info = "phone";
        },
        submitPhone() {
            if (this.input.phone !== "") {
                this.handleSubmit(
                    { data: {}, customFields: { phone: this.input.phone } },
                    (response) => {
                        this.phone = response.data.user.customFields.phone;
                        this.$store.commit("setPhone", response.data.user.customFields.phone);
                        this.alert_message = "Phone number updated successfully.";
                    }
                );
            }
        },
        editAltPhone() {
            this.header_text = "Edit Alt. Phone Number";
            this.editing_info = "altphone";
        },
        submitAltPhone() {
            if (this.input.altphone !== "") {
                this.handleSubmit(
                    { data: {}, customFields: { altphone: this.input.altphone } },
                    (response) => {
                        this.altphone = response.data.user.customFields.altphone;
                        this.$store.commit(
                            "setAltPhone",
                            response.data.user.customFields.altphone
                        );
                        this.alert_message = "Alt. phone number updated successfully.";
                    }
                );
            }
        },
        editEmail() {
            this.header_text = "Edit Email";
            this.header_message =
                "Note: you must enter your password when changing your email address.";
            this.editing_info = "email";
        },
        submitEmail() {
            if (this.input.email !== "" && this.input.password !== "") {
                var inpass = shajs("sha256").update(this.input.password).digest("hex");
                var dataObj = { data: { email: this.input.email, currentPassword: inpass } };
                this.handleSubmit(dataObj, (response) => {
                    this.email = response.data.user.emails[0].address;
                    this.$store.commit("setEmail", response.data.user.emails[0].address);
                    this.alert_message =
                        "Email updated successfully.  You will receive an email at (" +
                        this.email +
                        ") asking you to verify your address.";
                });
            }
        },
        editStatusText() {
            this.header_text = "Edit Status Text";
            if (this.$store.getters.isRadiologist) {
                this.editing_info = "statustext_rad";
            } else {
                this.editing_info = "statustext";
            }
        },
        submitStatusText() {
            this.handleSubmit({ data: { statusText: this.input.statusText } }, (response) => {
                this.statusText = response.data.user.statusText;
                this.$store.commit("setStatusText", response.data.user.statusText);
                this.alert_message = "Status Text updated successfully.";
            });
        },
        editLocation() {
            this.header_text = "Edit Location";
            this.editing_info = "location";
        },
        submitLocation() {
            if (this.input.location !== "") {
                this.handleSubmit(
                    { data: {}, customFields: { location: this.input.location } },
                    (response) => {
                        this.location = response.data.user.customFields.location;
                        this.$store.commit(
                            "setLocation",
                            response.data.user.customFields.location
                        );
                        this.alert_message = "Location updated successfully.";
                    }
                );
            }
        },
        updatePassword() {
            this.header_text = "Update Password";
            this.editing_info = "password";
        },
        submitUpdatePassword() {
            if (
                this.input.password !== "" &&
                this.input.new_password !== "" &&
                this.input.new_password_confirm !== "" &&
                this.input.new_password == this.input.new_password_confirm
            ) {
                var inpass = shajs("sha256").update(this.input.password).digest("hex");
                var dataObj = {
                    data: { newPassword: this.input.new_password, currentPassword: inpass },
                };
                this.handleSubmit(dataObj, () => {
                    this.alert_message = "Password updated successfully.";
                });
            }
        },
        handleSubmit(data, callback) {
            this.axios({
                method: "post",
                url: "/api/v1/users.updateOwnBasicInfo",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": this.$store.state.authToken,
                    "X-User-Id": this.$store.state.userId,
                },
                data: data,
            })
                .then((response) => {
                    if (response.data.success) {
                        this.clearForm();
                        callback(response);
                    }
                })
                .catch(() => {
                    this.clearForm();
                    this.is_error = true;
                    this.alert_message =
                        "There was an issue handling your request, \n" +
                        "please try again after a few minutes.";
                });
        },
    },
};
</script>

<style scoped>
.component-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
}

.alert-message {
    background-color: green;
    max-width: 350px;
    width: 90%;
    margin: auto;
    padding: 7px;
    border-radius: 4px;
    text-align: center;
}

.alert-message.error {
    background-color: #530404;
}

.user-profile {
    margin: 10px;
    padding: 20px;
    background-color: #1e2833;
    overflow: auto;
    border-radius: 4px;
}

.user-field-list {
    color: #999;
}

.user-profile-header {
    color: #fff;
    font-size: x-large;
    text-decoration: underline;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.user-profile-header-message {
    font-size: small;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    width: 100%;
}

.user-profile-header-message div {
    max-width: 300px;
}

.username-message {
    font-size: small;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.indent {
    padding-left: 10px;
}

.user-field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px;
}

.field-label {
    min-width: 150px;
    text-align: right;
    margin-right: 10px;
    font-size: medium;
}

.field-item {
    text-align: left;
    font-size: large;
    color: #fff;
}

.text-input {
    background: none;
    padding: 10px 5px;
    margin: 8px 0;
    font-size: medium;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    min-width: 250px;
}

.phone-format {
    font-size: x-small;
    color: #aaa;
}

.user-edit-form {
    min-width: 400px;
    padding-top: 10px;
}

.edit-button {
    width: 50%;
    font-size: large;
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 25px;
    text-shadow: none;
    outline: none;
    color: #fff;
}

.edit-button:hover {
    opacity: 0.8;
}

.edit-button:active {
    transform: translateY(1px);
}

.edit-button:focus {
    outline: none;
}
.edit-button::-moz-focus-inner {
    border: 0;
}

.button-container {
    width: 100%;
    min-width: 150px;
    display: flex;
    justify-content: center;
}

.edit-field-link {
    font-size: small;
    padding-left: 5px;
}

.update-password-link {
    font-size: large;
    padding: 10px;
}

.back-link,
.edit-field-link,
.update-password-link {
    color: #5af;
}

.back-link:hover,
.edit-field-link:hover,
.update-password-link:hover {
    color: #5cf;
}

.select-css {
    display: block;
    font-size: small;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}

.select-css option {
    font-weight: normal;
}
</style>

<template>
    <div class="about">Testing area</div>
</template>

<script>
export default {
    name: "about",
};
</script>

<style scoped>
.about {
    text-align: center;
}
</style>

<template>
    <div class="loginform">
        <div class="login-message-container" v-if="loginFormMessage !== ''">
            <div class="login-message" v-bind:class="{ error: loginFormError }">
                {{ loginFormMessage }}
            </div>
        </div>
        <form @submit.prevent="handleSubmit">
            <input
                class="email-input"
                type="email"
                v-model="input.email"
                placeholder="Email"
                required
                autocomplete="on"
            />
            <input
                class="password-input"
                type="password"
                v-model="input.password"
                placeholder="Password"
                required
            />
            <button class="login-button" type="submit">Log In</button>
            <div class="form-link">
                <a href="/register">Register</a>
            </div>
            <div class="form-link">
                <a href="/forgot-password">Forgot Password?</a>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "LoginForm",
    data() {
        return {
            input: {
                email: "",
                password: "",
            },
            isError: false,
            loginFormMessage: "",
            loginFormError: false,
        };
    },
    created: function () {
        this.loginFormMessage = this.$store.state.loginForm.message;
        this.loginFormError = this.$store.state.loginForm.isError;
        this.$store.commit("setLoginFormMessage", { message: "", error: false });
    },
    methods: {
        handleSubmit() {
            if (this.input.email !== "" && this.input.password !== "") {
                this.login();
            }
        },
        login() {
            this.axios({
                method: "post",
                url: "/api/v1/login",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    user: this.input.email,
                    password: this.input.password,
                },
            })
                .then((response) => {
                    if (response.data.status === "success") {
                        let phoneNumber = response.data.data.me.customFields.phone || "N/A";
                        let location = response.data.data.me.customFields.location || "N/A";
                        let email = response.data.data.me.emails[0].address || "N/A";
                        this.$store.commit("setAuthToken", response.data.data.authToken);
                        this.$store.commit("setUserName", response.data.data.me.username);
                        this.$store.commit("setName", response.data.data.me.name);
                        this.$store.commit("setUserId", response.data.data.userId);
                        this.$store.commit("setPhone", phoneNumber);
                        this.$store.commit("setLocation", location);
                        this.$store.commit("setEmail", email);
                        document.getElementById("RocketFrame").contentWindow.postMessage(
                            {
                                externalCommand: "login-with-token",
                                token: response.data.data.authToken,
                            },
                            "*"
                        );
                        this.$router.push("/");
                    }
                })
                .catch(() => {
                    this.loginFormError = true;
                    this.loginFormMessage = "Email or Password Incorrect";
                });
        },
    },
};
</script>

<style scoped>
.loginform {
    height: 600px;
    position: relative;
    background-color: #111111;
}

.loginform form {
    max-width: 320px;
    width: 90%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 40px;
    background-color: #1e2833;
    color: #fff;
    overflow: auto;
    border-radius: 4px;
}

.email-input,
.password-input {
    background: none;
    padding: 10px 5px;
    margin: 8px 0;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    width: 90%;
}

.login-button {
    width: 50%;
    font-size: large;
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    color: #fff;
}

.login-button:hover {
    opacity: 0.8;
}

.login-button:active {
    transform: translateY(1px);
}

.form-link {
    margin-top: 20px;
}

.form-link a {
    font-size: 14px;
    color: #4499ff;
}

.login-button:focus,
.form-link a:focus {
    outline: none;
}

.login-button::-moz-focus-inner,
.form-link a::-moz-focus-inner {
    border: 0;
}

.login-message-container {
    padding-top: 20px;
}

.login-message {
    max-width: 400px;
    width: 90%;
    margin: auto;
    padding: 7px;
    border-radius: 4px;
    background: green;
}

.error {
    background-color: #530404;
}
</style>

<template>
    <div class="qc-metrics-container">
        <div class="form-container"><QCMetricsForm /></div>
        <div class="search-container"><QCMetricsSearch /></div>
    </div>
</template>

<script>
import QCMetricsForm from "@/components/QCMetricsForm.vue";
import QCMetricsSearch from "@/components/QCMetricsSearch.vue";

export default {
    name: "QCMetrics",
    components: {
        QCMetricsForm,
        QCMetricsSearch,
    },
};
</script>

<style scoped>
.qc-metrics-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 80vh;
}

.form-container {
    width: 440px;
}

.search-container {
    width: calc(100vw - 500px);
}
</style>

<template>
    <div class="component-container">
        <div class="logout-link">
            <a href="#" @click="logoutFromAll()">{{ link_message }}</a>
        </div>
        <div v-show="logout_message == ''" class="note">* You will stay logged in here</div>
        <div
            class="logout-message"
            v-bind:class="{ error: is_error }"
            v-show="logout_message !== ''"
        >
            {{ logout_message }}
        </div>
    </div>
</template>

<script>
export default {
    name: "LogoutFromAll",
    data() {
        return {
            link_message: "Logout From All Other Locations",
            logout_message: "",
            is_error: false,
        };
    },
    methods: {
        logoutFromAll() {
            this.axios({
                method: "post",
                url: "/api/v1/users.removeOtherTokens",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": this.$store.state.authToken,
                    "X-User-Id": this.$store.state.userId,
                },
            })
                .then(() => {
                    this.logout_message = "You have been successfully logged out.";
                })
                .catch(() => {
                    this.is_error = true;
                    this.logout_message =
                        "There was an issue handling your request, \n" +
                        "please try again after a few minutes.";
                });
        },
    },
};
</script>

<style scoped>
.component-container {
    width: 100%;
}

.logout-link,
.logout-message,
.note {
    margin-left: auto;
    margin-right: auto;
    min-width: 200px;
    text-align: center;
}

.logout-message {
    background: green;
    color: #ffffff;
    padding: 5px;
    border-radius: 4px;
    max-width: 300px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
}

.error {
    background-color: #f00;
}

a {
    color: #5af;
}

a:hover {
    color: #5cf;
}

.note {
    font-size: 12px;
    font-style: italic;
}
</style>

import { render, staticRenderFns } from "./RadOnCall.vue?vue&type=template&id=ead4ecec&scoped=true&"
import script from "./RadOnCall.vue?vue&type=script&lang=js&"
export * from "./RadOnCall.vue?vue&type=script&lang=js&"
import style0 from "./RadOnCall.vue?vue&type=style&index=0&id=ead4ecec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ead4ecec",
  null
  
)

export default component.exports
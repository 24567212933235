<template>
    <div id="app">
        <iframe
            v-show="displayRocketFrame()"
            id="RocketFrame"
            src="https://chat.bra-communicator.com/home"
        ></iframe>
        <div id="container">
            <div class="header">
                <NavBar />
            </div>
            <div class="content">
                <TextChatLayer />
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
                <div v-show="displayNextCloudFrame()" id="NextCloudFrame">
                    <iframe src="https://files.bra-communicator.com"></iframe>
                </div>
            </div>
            <div class="footer">
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import TextChatLayer from "@/components/TextChatLayer.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        NavBar,
        TextChatLayer,
        Footer,
    },
    methods: {
        displayRocketFrame() {
            return this.$store.state.displayRocketFrame;
        },
        displayNextCloudFrame() {
            return this.$router.currentRoute.name == "files";
        },
    },
};
</script>

<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #cccccc;
    background: #111;
    height: 100%;
    width: 100%;
}

#RocketFrame {
    position: fixed;
    width: 800px;
    top: 55px;
    left: 5px;
    height: calc(100% - 200px);
}

html,
body {
    margin: 0;
    padding: 0;
    background: #111;
    height: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

#container {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    min-height: auto;
    flex-grow: 0;
    z-index: 9999;
}

.content {
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow: auto;
}

.footer {
    flex-grow: 0;
    min-height: auto;
    background: #333;
}
/*
@media all and (max-width: 900px) {
    .header {
        min-height: auto;
    }
}
*/
</style>

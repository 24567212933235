<template>
    <div class="register">
        <RegisterForm />
    </div>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";

export default {
    name: "register",
    components: {
        RegisterForm,
    },
};
</script>

<style scoped>
.register {
    text-align: center;
    background-color: #111111;
}
</style>

<template>
    <div class="component-container">
        <TextChatWindow
            v-for="text_window in text_chat_windows"
            v-bind:key="text_window.username"
            :username="text_window.username"
            :title="text_window.title"
            :is-visible="text_window.is_visible"
            :start-open="text_window.start_open"
            :show-button="text_window.show_button"
            :init-x="text_window.x"
            :init-y="text_window.y"
            :init-h="text_window.h"
            :init-w="text_window.w"
        ></TextChatWindow>
    </div>
</template>

<script>
import TextChatWindow from "@/components/TextChatWindow.vue";

export default {
    name: "TextChatLayer",
    components: {
        TextChatWindow,
    },
    computed: {
        text_chat_windows() {
            return this.$store.state.text_chat_windows;
        },
    },
};
</script>

<style scoped></style>

<template>
    <div class="phone-list-container">
        <div class="search-container"><PhoneListSearch /></div>
    </div>
</template>

<script>
import PhoneListSearch from "@/components/PhoneListSearch.vue";

export default {
    name: "PhoneList",
    components: {
        PhoneListSearch,
    },
};
</script>

<style scoped>
.phone-list-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 80vh;
}

.form-container {
    width: 440px;
}

.search-container {
    width: 100%;
}
</style>

<template>
    <div class="forgot-password">
        <ForgotPasswordForm />
    </div>
</template>

<script>
import ForgotPasswordForm from "@/components/ForgotPasswordForm.vue";

export default {
    name: "ForgotPassword",
    components: {
        ForgotPasswordForm,
    },
};
</script>

<style scoped>
.forgot-password {
    text-align: center;
    background-color: #111111;
}
</style>

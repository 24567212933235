<template>
    <div class="registerform">
        <div class="register-message-container" v-if="registerFormMessage !== ''">
            <div class="register-message" v-bind:class="{ error: registerFormError }">
                {{ registerFormMessage }}
            </div>
        </div>
        <form @submit.prevent="handleSubmit">
            <input
                class="name-input"
                type="text"
                v-model="input.name"
                placeholder="Full Name"
                required
                autocomplete="on"
            />
            <input
                class="email-input"
                type="email"
                v-model="input.email"
                placeholder="Email"
                required
                autocomplete="on"
            />
            <input
                class="password-input"
                type="password"
                v-model="input.password"
                placeholder="Password"
                required
            />
            <input
                class="password-input"
                type="password"
                v-model="input.password_confirm"
                placeholder="Confirm Password"
                required
            />
            <input
                class="phone-input"
                type="tel"
                v-model="input.phone"
                placeholder="Callback Phone #"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                required
                autocomplete="on"
            />
            <div class="phone-format">Format: 123-456-7890</div>
            <input
                class="phone-input"
                type="tel"
                v-model="input.altphone"
                placeholder="Alternate Phone # (optional)"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                autocomplete="on"
            />
            <div class="phone-format">Format: 123-456-7890</div>
            <select class="select-css" v-model="input.location">
                <option :value="null" disabled>Location</option>
                <option>Bowman</option>
                <option>Sakakawea/Hazen</option>
                <option>Elgin</option>
                <option>CHI Minot</option>
                <option>CHI Mandan</option>
                <option>CHI Mandan</option>
                <option>CHI Garrison</option>
                <option>CHI Turtle Lake</option>
                <option>Harvey</option>
                <option>Linton</option>
                <option>Ashley</option>
                <option>Wishek</option>
                <option>Hettinger</option>
                <option>CHI St. Alexius Bismarck</option>
            </select>
            <div v-show="show_recaptcha">
                <Recaptcha></Recaptcha>
            </div>
            <button
                v-show="show_register_button"
                id="register-button"
                class="register-button"
                type="submit"
            >
                Register
            </button>
            <button
                class="register-button"
                v-show="!show_register_button"
                v-on:click="resetForm"
            >
                Register Another Account
            </button>
        </form>
    </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
export default {
    name: "RegisterForm",
    components: {
        Recaptcha,
    },
    data() {
        return {
            input: {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
                location: null,
                phone: "",
                altphone: "",
            },
            username: "",
            registerFormMessage: "",
            registerFormError: false,
            register_secret: "AX4FswccgPAF3bQDs",
            show_recaptcha: true,
            show_register_button: true,
        };
    },
    methods: {
        resetForm() {
            this.input = {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
                location: null,
                phone: "",
                altphone: "",
            };
            this.username = "";
            this.registerFormMessage = "";
            this.registerFormError = false;
            this.show_register_button = true;
            this.show_recaptcha = true;
        },
        setupUsername() {
            this.username = this.input.email.toLowerCase().replace(/@/g, "_at_");
        },
        handleSubmit() {
            this.registerFormError = false;
            this.registerFormMessage = "";
            if (
                this.input.password !== "" &&
                this.input.password !== this.input.password_confirm
            ) {
                this.registerFormError = true;
                this.registerFormMessage += "The passwords you entered do not match.";
            }

            if (this.input.location == null) {
                this.registerFormError = true;
                this.registerFormMessage += "Please select a location. ";
            }

            if (
                this.input.name !== "" &&
                this.input.email !== "" &&
                this.input.password !== "" &&
                this.input.phone !== "" &&
                this.input.location !== null &&
                this.input.password === this.input.password_confirm &&
                window.grecaptcha.getResponse() !== ""
            ) {
                this.setupUsername();
                this.register();
            }
        },
        clearForm() {
            this.input = {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
                location: null,
                phone: "",
                altphone: "",
            };
            this.username = "";
        },
        register() {
            this.axios({
                method: "post",
                url: "/api/v1/users.register",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    username: this.username,
                    email: this.input.email,
                    pass: this.input.password,
                    name: this.input.name,
                    secretURL: this.register_secret,
                    location: this.input.location,
                    phone: this.input.phone,
                    altphone: this.input.altphone,
                },
            })
                .then((response) => {
                    if (response.data.success) {
                        this.registerFormError = false;
                        this.registerFormMessage =
                            "Account has been registered and will be activated " +
                            "once the BRA team manually approves it.";
                        document.getElementById("register-button").disabled = true;
                        this.show_recaptcha = false;
                        this.show_register_button = false;
                    } else {
                        this.registerFormError = true;
                        this.registerFormMessage =
                            "There was an error registering your account.";
                    }
                })
                .catch(() => {
                    this.registerFormError = true;
                    this.registerFormMessage = "There was an error registering your account.";
                });
        },
    },
};
</script>

<style scoped>
.registerform {
    height: 600px;
    position: relative;
    background-color: #111111;
}

.registerform form {
    max-width: 320px;
    width: 90%;
    padding: 40px;
    background-color: #1e2833;
    color: #fff;
    overflow: auto;
    border-radius: 4px;
    display: inline-block;
}

.name-input,
.email-input,
.password-input,
.phone-input {
    background: none;
    padding: 10px 5px;
    margin: 8px 0;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    width: 90%;
}

.register-button {
    width: 50%;
    font-size: large;
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    color: #fff;
}

.register-button:hover {
    opacity: 0.8;
}

.register-button:active {
    transform: translateY(1px);
}

.phone-format {
    font-size: 12px;
    color: #aaa;
}

.register-button:focus {
    outline: none;
}

.register-button::-moz-focus-inner {
    border: 0;
}

.register-message-container {
    padding-top: 30px;
    padding-bottom: 20px;
}

.register-message {
    max-width: 400px;
    width: 90%;
    margin: auto;
    padding: 7px;
    border-radius: 4px;
    background: green;
}

.error {
    background-color: #530404;
}

.select-css {
    display: block;
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 0px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
    display: none;
}

.select-css:hover {
    border-color: #888;
}

.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}

.select-css option {
    font-weight: normal;
}
</style>

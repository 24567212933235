<template>
    <div class="template-container">
        <div class="document-list-container">
            <div class="document-list-heading">
                <div class="document-list-heading-left">
                    <div class="doc-title">MDCT Protocol Documents</div>
                    <div class="doc-subtitle">List last updated on 2/22/2020</div>
                </div>
            </div>
            <div class="documents-container small-list">
                <div
                    class="doc"
                    v-for="doc in documents"
                    v-bind:key="doc.image"
                    v-bind:class="{ active: isActive(doc) }"
                >
                    <a href="#" v-on:click="loadDocImage(doc.image)">{{ doc.name }}</a>
                </div>
            </div>
        </div>
        <div class="current-image">
            <img :src="currentImage" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ReferenceDocumentsList",
    data: function () {
        return {
            largeWindow: false,
            currentImage: "",
            buttonText: "Expand",
            documents: [
                {
                    name: "Adult Carotid Angio- 40 slice",
                    image: require("../assets/documents/Adult_Carotid_Angio-_40_slice.png"),
                },
                {
                    name: "Adult Carotid Angio- 64 slice",
                    image: require("../assets/documents/Adult_Carotid_Angio-_64_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis with- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_with-_40_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis with- 64 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_with-_64_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis with and without- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_with_and_without-_40_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis with and without- 64 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_with_and_without-_64_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis without- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_without-_40_slice.png"),
                },
                {
                    name: "Adult Chest Abd Pelvis without- 64 slice",
                    image: require("../assets/documents/Adult_Chest_Abd_Pelvis_without-_64_slice.png"),
                },
                {
                    name: "Adult Chest Hi Res.- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Hi_Res.-_40_slice.png"),
                },
                {
                    name: "Adult Chest Hi Res.- 64slice",
                    image: require("../assets/documents/Adult_Chest_Hi_Res.-_64slice.png"),
                },
                {
                    name: "Adult Chest Low Dose non screening- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Low_Dose_non_screening-_40_slice.png"),
                },
                {
                    name: "Adult Chest Low Dose non screening- 64 slice",
                    image: require("../assets/documents/Adult_Chest_Low_Dose_non_screening-_64_slice.png"),
                },
                {
                    name: "Adult Chest PE Abdomen Pelvis- 40 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Abdomen_Pelvis-_40_slice.png"),
                },
                {
                    name: "Adult Chest PE Abdomen Pelvis- 64 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Abdomen_Pelvis-_64_slice.png"),
                },
                {
                    name: "Adult Chest PE Large Protocol- 40 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Large_Protocol-_40_slice.png"),
                },
                {
                    name: "Adult Chest PE Large Protocol- 64 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Large_Protocol-_64_slice.png"),
                },
                {
                    name: "Adult Chest PE Protocol- 40 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Protocol-_40_slice.png"),
                },
                {
                    name: "Adult Chest PE Protocol- 64 slice",
                    image: require("../assets/documents/Adult_Chest_PE_Protocol-_64_slice.png"),
                },
                {
                    name: "Adult Chest Super Dimension- 40 slice",
                    image: require("../assets/documents/Adult_Chest_Super_Dimension-_40_slice.png"),
                },
                {
                    name: "Adult Chest Super Dimension- 64 slice",
                    image: require("../assets/documents/Adult_Chest_Super_Dimension-_64_slice.png"),
                },
                {
                    name: "Adult Chest with- 40 slice",
                    image: require("../assets/documents/Adult_Chest_with-_40_slice.png"),
                },
                {
                    name: "Adult Chest with- 64 slice",
                    image: require("../assets/documents/Adult_Chest_with-_64_slice.png"),
                },
                {
                    name: "Adult Chest with Large patient- 40 slice",
                    image: require("../assets/documents/Adult_Chest_with_Large_patient-_40_slice.png"),
                },
                {
                    name: "Adult Chest with Large patient- 64 slice",
                    image: require("../assets/documents/Adult_Chest_with_Large_patient-_64_slice.png"),
                },
                {
                    name: "Adult Chest without- 40 slice",
                    image: require("../assets/documents/Adult_Chest_without-_40_slice.png"),
                },
                {
                    name: "Adult Chest without- 64 slice",
                    image: require("../assets/documents/Adult_Chest_without-_64_slice.png"),
                },
                {
                    name: "Adult Coronary CTA",
                    image: require("../assets/documents/Adult_Coronary_CTA.png"),
                },
                {
                    name: "Adult C-Spine- 40 slice",
                    image: require("../assets/documents/Adult_C-Spine-_40_slice.png"),
                },
                {
                    name: "Adult C-Spine- 64 slice",
                    image: require("../assets/documents/Adult_C-Spine-_64_slice.png"),
                },
                {
                    name: "Adult C-Spine Large- 40 slice",
                    image: require("../assets/documents/Adult_C-Spine_Large-_40_slice.png"),
                },
                {
                    name: "Adult C-Spine Large- 64 slice",
                    image: require("../assets/documents/Adult_C-Spine_Large-_64_slice.png"),
                },
                {
                    name: "Adult DVT- 40 slice",
                    image: require("../assets/documents/Adult_DVT-_40_slice.png"),
                },
                {
                    name: "Adult DVT- 64 slice",
                    image: require("../assets/documents/Adult_DVT-_64_slice.png"),
                },
                {
                    name: "Adult Enterography- 40 slice",
                    image: require("../assets/documents/Adult_Enterography-_40_slice.png"),
                },
                {
                    name: "Adult Enterography- 64 slice",
                    image: require("../assets/documents/Adult_Enterography-_64_slice.png"),
                },
                {
                    name: "Adult Eval Rot component- 40 slice",
                    image: require("../assets/documents/Adult_Eval_Rot_component-_40_slice.png"),
                },
                {
                    name: "Adult Eval Rot component- 64 slice",
                    image: require("../assets/documents/Adult_Eval_Rot_component-_64_slice.png"),
                },
                {
                    name: "Adult Facial-Orbits- 40 slice",
                    image: require("../assets/documents/Adult_Facial-Orbits-_40_slice.png"),
                },
                {
                    name: "Adult Facial-Orbits- 64 slice",
                    image: require("../assets/documents/Adult_Facial-Orbits-_64_slice.png"),
                },
                {
                    name: "Adult Fast Trauma Head- 40 Slice",
                    image: require("../assets/documents/Adult_Fast_Trauma_Head-_40_Slice.png"),
                },
                {
                    name: "Adult Fast Trauma Head- 64 Slice",
                    image: require("../assets/documents/Adult_Fast_Trauma_Head-_64_Slice.png"),
                },
                {
                    name: "Adult Head- 40 slice",
                    image: require("../assets/documents/Adult_Head-_40_slice.png"),
                },
                {
                    name: "Adult Head- 64 slice",
                    image: require("../assets/documents/Adult_Head-_64_slice.png"),
                },
                {
                    name: "Adult Head Angio- 40 slice",
                    image: require("../assets/documents/Adult_Head_Angio-_40_slice.png"),
                },
                {
                    name: "Adult Head Angio- 64 slice",
                    image: require("../assets/documents/Adult_Head_Angio-_64_slice.png"),
                },
                {
                    name: "Adult Head Neck Angio- 40 slice",
                    image: require("../assets/documents/Adult_Head_Neck_Angio-_40_slice.png"),
                },
                {
                    name: "Adult Head Neck Angio- 64 slice",
                    image: require("../assets/documents/Adult_Head_Neck_Angio-_64_slice.png"),
                },
                {
                    name: "Adult Hematuria Chest Abdomen Pelvis- 40 slice",
                    image: require("../assets/documents/Adult_Hematuria_Chest_Abdomen_Pelvis-_40_slice.png"),
                },
                {
                    name: "Adult Hematuria Chest Abdomen Pelvis- 64 slice",
                    image: require("../assets/documents/Adult_Hematuria_Chest_Abdomen_Pelvis-_64_slice.png"),
                },
                {
                    name: "Adult Hematuria Protocol- 40 slice",
                    image: require("../assets/documents/Adult_Hematuria_Protocol-_40_slice.png"),
                },
                {
                    name: "Adult Hematuria Protocol- 64 slice",
                    image: require("../assets/documents/Adult_Hematuria_Protocol-_64_slice.png"),
                },
                {
                    name: "Adult IAC- 40 slice",
                    image: require("../assets/documents/Adult_IAC-_40_slice.png"),
                },
                {
                    name: "Adult IAC- 64 slice",
                    image: require("../assets/documents/Adult_IAC-_64_slice.png"),
                },
                {
                    name: "Adult Knees and Feet- 40 slice",
                    image: require("../assets/documents/Adult_Knees_and_Feet-_40_slice.png"),
                },
                {
                    name: "Adult Knees and Feet- 64 slice",
                    image: require("../assets/documents/Adult_Knees_and_Feet-_64_slice.png"),
                },
                {
                    name: "Adult Low Dose Stone Protocol Follow-up - 40 slice",
                    image: require("../assets/documents/Adult_Low_Dose_Stone_Protocol_Follow-up_-_40_slice.png"),
                },
                {
                    name: "Adult Low Dose Stone Protocol Follow-up - 64 slice",
                    image: require("../assets/documents/Adult_Low_Dose_Stone_Protocol_Follow-up_-_64_slice.png"),
                },
                {
                    name: "Adult L-Spine- 40 slice",
                    image: require("../assets/documents/Adult_L-Spine-_40_slice.png"),
                },
                {
                    name: "Adult L-Spine- 64 slice",
                    image: require("../assets/documents/Adult_L-Spine-_64_slice.png"),
                },
                {
                    name: "Adult L-Spine Large- 40 slice",
                    image: require("../assets/documents/Adult_L-Spine_Large-_40_slice.png"),
                },
                {
                    name: "Adult L-Spine Large- 64 slice",
                    image: require("../assets/documents/Adult_L-Spine_Large-_64_slice.png"),
                },
                {
                    name: "Adult L-Spine post Myelogram- 40 slice",
                    image: require("../assets/documents/Adult_L-Spine_post_Myelogram-_40_slice.png"),
                },
                {
                    name: "Adult L-Spine post Myelogram- 64 slice",
                    image: require("../assets/documents/Adult_L-Spine_post_Myelogram-_64_slice.png"),
                },
                {
                    name: "Adult Pelvis  Bone- 40 slice",
                    image: require("../assets/documents/Adult_Pelvis__Bone-_40_slice.png"),
                },
                {
                    name: "Adult Pelvis  Bone- 64 slice",
                    image: require("../assets/documents/Adult_Pelvis__Bone-_64_slice.png"),
                },
                {
                    name: "Adult Prophecy Ankle- 40 slice",
                    image: require("../assets/documents/Adult_Prophecy_Ankle-_40_slice.png"),
                },
                {
                    name: "Adult Prophecy Ankle- 64 slice",
                    image: require("../assets/documents/Adult_Prophecy_Ankle-_64_slice.png"),
                },
                {
                    name: "Adult Shoulder- 40 slice",
                    image: require("../assets/documents/Adult_Shoulder-_40_slice.png"),
                },
                {
                    name: "Adult Shoulder- 64 slice",
                    image: require("../assets/documents/Adult_Shoulder-_64_slice.png"),
                },
                {
                    name: "Adult Shoulder Dr. Juelson- 40 slice",
                    image: require("../assets/documents/Adult_Shoulder_Dr._Juelson-_40_slice.png"),
                },
                {
                    name: "Adult Shoulder Dr. Juelson- 64 slice",
                    image: require("../assets/documents/Adult_Shoulder_Dr._Juelson-_64_slice.png"),
                },
                {
                    name: "Adult Single Area Perfusion-64 slice",
                    image: require("../assets/documents/Adult_Single_Area_Perfusion-64_slice.png"),
                },
                {
                    name: "Adult Sinus- 40 slice",
                    image: require("../assets/documents/Adult_Sinus-_40_slice.png"),
                },
                {
                    name: "Adult Sinus- 64 slice",
                    image: require("../assets/documents/Adult_Sinus-_64_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck With- 40 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_With-_40_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck With- 64 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_With-_64_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck Without- 40 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_Without-_40_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck Without- 64 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_Without-_64_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck Without and With- 40 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_Without_and_With-_40_slice.png"),
                },
                {
                    name: "Adult Soft Tissue Neck Without and With- 64 slice",
                    image: require("../assets/documents/Adult_Soft_Tissue_Neck_Without_and_With-_64_slice.png"),
                },
                {
                    name: "Adult Spiral Head Bone Synthesis- 40 Slice",
                    image: require("../assets/documents/Adult_Spiral_Head_Bone_Synthesis-_40_Slice.png"),
                },
                {
                    name: "Adult Spiral Head Bone Synthesis- 64 Slice",
                    image: require("../assets/documents/Adult_Spiral_Head_Bone_Synthesis-_64_Slice.png"),
                },
                {
                    name: "Adult stroke with rapid-64 slice",
                    image: require("../assets/documents/Adult_stroke_with_rapid-64_slice.png"),
                },
                {
                    name: "Adult Stryker Knee- 40 slice",
                    image: require("../assets/documents/Adult_Stryker_Knee-_40_slice.png"),
                },
                {
                    name: "Adult Stryker Knee- 64 slice",
                    image: require("../assets/documents/Adult_Stryker_Knee-_64_slice.png"),
                },
                {
                    name: "Adult Superimposition Knee- 40 slice",
                    image: require("../assets/documents/Adult_Superimposition_Knee-_40_slice.png"),
                },
                {
                    name: "Adult Superimposition Knee- 64 slice",
                    image: require("../assets/documents/Adult_Superimposition_Knee-_64_slice.png"),
                },
                {
                    name: "Adult Trauma Head& C-spine- 40 slice",
                    image: require("../assets/documents/Adult_Trauma_Head&_C-spine-_40_slice.png"),
                },
                {
                    name: "Adult Trauma Head& C-spine- 64 slice",
                    image: require("../assets/documents/Adult_Trauma_Head&_C-spine-_64_slice.png"),
                },
                {
                    name: "Adult Truematch Knee- 40 slice",
                    image: require("../assets/documents/Adult_Truematch_Knee-_40_slice.png"),
                },
                {
                    name: "Adult Truematch Knee- 64 slice",
                    image: require("../assets/documents/Adult_Truematch_Knee-_64_slice.png"),
                },
                {
                    name: "Adult T-Spine- 40 slice",
                    image: require("../assets/documents/Adult_T-Spine-_40_slice.png"),
                },
                {
                    name: "Adult T-Spine- 64 slice",
                    image: require("../assets/documents/Adult_T-Spine-_64_slice.png"),
                },
                {
                    name: "Adult Upper Extremity- 40 slice",
                    image: require("../assets/documents/Adult_Upper_Extremity-_40_slice.png"),
                },
                {
                    name: "Adult Upper Extremity- 64 slice",
                    image: require("../assets/documents/Adult_Upper_Extremity-_64_slice.png"),
                },
                {
                    name: "Adult Urogram Renal Mass - 40 slice",
                    image: require("../assets/documents/Adult_Urogram_Renal_Mass_-_40_slice.png"),
                },
                {
                    name: "Adult Urogram Renal Mass - 64 slice",
                    image: require("../assets/documents/Adult_Urogram_Renal_Mass_-_64_slice.png"),
                },
                {
                    name: "Adult Volume Neuro Perfusion- 64 slice",
                    image: require("../assets/documents/Adult_Volume_Neuro_Perfusion-_64_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 0kg 9kg- 40 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_0kg_9kg-_40_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 0kg 9kg- 64 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_0kg_9kg-_64_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 10kg 26kg- 40 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_10kg_26kg-_40_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 10kg 26kg- 64 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_10kg_26kg-_64_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 27kg 45kg- 40 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_27kg_45kg-_40_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 27kg 45kg- 64 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_27kg_45kg-_64_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 46kg 100kg- 40 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_46kg_100kg-_40_slice.png"),
                },
                {
                    name: "Pediatric Abdomen Pelvis 46kg 100kg- 64 slice",
                    image: require("../assets/documents/Pediatric_Abdomen_Pelvis_46kg_100kg-_64_slice.png"),
                },
                {
                    name: "Pediatric Angio Head- 40 slice",
                    image: require("../assets/documents/Pediatric_Angio_Head-_40_slice.png"),
                },
                {
                    name: "Pediatric Angio Head- 64 slice",
                    image: require("../assets/documents/Pediatric_Angio_Head-_64_slice.png"),
                },
                {
                    name: "Pediatric Chest with- 40 slice",
                    image: require("../assets/documents/Pediatric_Chest_with-_40_slice.png"),
                },
                {
                    name: "Pediatric Chest with- 64 slice",
                    image: require("../assets/documents/Pediatric_Chest_with-_64_slice.png"),
                },
                {
                    name: "Pediatric Chest without- 40 slice",
                    image: require("../assets/documents/Pediatric_Chest_without-_40_slice.png"),
                },
                {
                    name: "Pediatric Chest without- 64 slice",
                    image: require("../assets/documents/Pediatric_Chest_without-_64_slice.png"),
                },
                {
                    name: "Pediatric Chest without and with- 40 slice",
                    image: require("../assets/documents/Pediatric_Chest_without_and_with-_40_slice.png"),
                },
                {
                    name: "Pediatric Chest without and with- 64 slice",
                    image: require("../assets/documents/Pediatric_Chest_without_and_with-_64_slice.png"),
                },
                {
                    name: "Pediatric Facial Bones Orbits- 40 slice",
                    image: require("../assets/documents/Pediatric_Facial_Bones_Orbits-_40_slice.png"),
                },
                {
                    name: "Pediatric Facial Bones Orbits- 64 slice",
                    image: require("../assets/documents/Pediatric_Facial_Bones_Orbits-_64_slice.png"),
                },
                {
                    name: "Pediatric Head-40 slice",
                    image: require("../assets/documents/Pediatric_Head-40_slice.png"),
                },
                {
                    name: "Pediatric Head-64 slice",
                    image: require("../assets/documents/Pediatric_Head-64_slice.png"),
                },
                {
                    name: "Pediatric IAC- 40 slice",
                    image: require("../assets/documents/Pediatric_IAC-_40_slice.png"),
                },
                {
                    name: "Pediatric IAC- 64 slice",
                    image: require("../assets/documents/Pediatric_IAC-_64_slice.png"),
                },
                {
                    name: "Pediatric Lower Extremity- 40 slice",
                    image: require("../assets/documents/Pediatric_Lower_Extremity-_40_slice.png"),
                },
                {
                    name: "Pediatric Lower Extremity- 64 slice",
                    image: require("../assets/documents/Pediatric_Lower_Extremity-_64_slice.png"),
                },
                {
                    name: "Pediatric Neck with- 40 slice",
                    image: require("../assets/documents/Pediatric_Neck_with-_40_slice.png"),
                },
                {
                    name: "Pediatric Neck with- 64 slice",
                    image: require("../assets/documents/Pediatric_Neck_with-_64_slice.png"),
                },
                {
                    name: "Pediatric Neck without- 40 slice",
                    image: require("../assets/documents/Pediatric_Neck_without-_40_slice.png"),
                },
                {
                    name: "Pediatric Neck without- 64 slice",
                    image: require("../assets/documents/Pediatric_Neck_without-_64_slice.png"),
                },
                {
                    name: "Pediatric Patients CTDI",
                    image: require("../assets/documents/Pediatric_Patients_CTDI.png"),
                },
                {
                    name: "Pediatric Pelvis- 40 slice",
                    image: require("../assets/documents/Pediatric_Pelvis-_40_slice.png"),
                },
                {
                    name: "Pediatric Pelvis- 64 slice",
                    image: require("../assets/documents/Pediatric_Pelvis-_64_slice.png"),
                },
                {
                    name: "Pediatric Shoulder- 40 slice",
                    image: require("../assets/documents/Pediatric_Shoulder-_40_slice.png"),
                },
                {
                    name: "Pediatric Shoulder- 64 slice",
                    image: require("../assets/documents/Pediatric_Shoulder-_64_slice.png"),
                },
                {
                    name: "Pediatric Sinus- 40 slice",
                    image: require("../assets/documents/Pediatric_Sinus-_40_slice.png"),
                },
                {
                    name: "Pediatric Sinus- 64 slice",
                    image: require("../assets/documents/Pediatric_Sinus-_64_slice.png"),
                },
                {
                    name: "Pediatric Spine- 40 slice",
                    image: require("../assets/documents/Pediatric_Spine-_40_slice.png"),
                },
                {
                    name: "Pediatric Spine- 64 slice",
                    image: require("../assets/documents/Pediatric_Spine-_64_slice.png"),
                },
                {
                    name: "Pediatric Upper Extremity- 40 slice",
                    image: require("../assets/documents/Pediatric_Upper_Extremity-_40_slice.png"),
                },
                {
                    name: "Pediatric Upper Extremity- 64 slice",
                    image: require("../assets/documents/Pediatric_Upper_Extremity-_64_slice.png"),
                },
            ],
        };
    },
    methods: {
        loadDocImage(image) {
            this.currentImage = image;
        },
        isActive(doc) {
            if (doc.image == this.currentImage) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style scoped>
a {
    color: #42b983;
    font-size: small;
}

a:hover {
    color: #56f0aa;
}

div.active a {
    color: #5af;
    font-size: medium;
}

div.active a:hover {
    color: #5cf;
}

div.active {
    padding-top: 9px;
    padding-bottom: 6px;
}

.current-image {
    height: calc(100vh - 75px);
    margin-left: 40px;
    margin-top: 10px;
    overflow-y: scroll;
}

.documents-container {
    height: calc(100vh - 125px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    transition: 0.5s ease-out;
    border: 1px solid #555;
    align-items: flex-start;
    overflow-y: scroll;
    margin-top: 5px;
    margin-left: 10px;
    padding: 5px;
}

.small-list {
    min-width: 200px;
    flex-wrap: nowrap;
}

.doc {
    padding-top: 3px;
    padding-left: 10px;
}

.template-container {
    display: flex;
    flex-direction: row;
}

.document-list-heading {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.document-list-heading-left {
    padding-left: 20px;
}

.document-list-heading-right {
    align-self: center;
    margin-left: 20px;
}

.doc-title {
    font-size: large;
    padding-top: 10px;
}

.doc-subtitle {
    font-size: small;
    color: #999999;
}

button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
}

button:hover {
    opacity: 0.8;
}

button:active {
    transform: translateY(1px);
}

@media all and (max-width: 1000px) {
    .expand-button {
        display: none;
    }
}
</style>

<template>
    <div class="forgot-password-form">
        <form @submit.prevent="handleSubmit">
            <input
                class="email-input"
                type="email"
                v-model="input.email"
                placeholder="Email"
                required
                autocomplete="on"
                v-show="show_form"
            />
            <div v-show="show_form">
                <Recaptcha></Recaptcha>
            </div>
            <button class="forgot-password-button" type="submit" v-show="show_form">
                Reset Password
            </button>
            <div class="forgot-password-message">{{ success_message }}</div>
        </form>
    </div>
</template>

<script>
import Recaptcha from "@/components/Recaptcha.vue";
export default {
    name: "ForgotPasswordForm",
    components: {
        Recaptcha,
    },
    data() {
        return {
            input: {
                email: "",
            },
            success_message: "",
            show_form: true,
        };
    },
    methods: {
        handleSubmit() {
            if (this.input.email !== "" && window.grecaptcha.getResponse() !== "") {
                this.sendForgotPasswordRequest();
            }
        },
        sendForgotPasswordRequest() {
            this.axios({
                method: "post",
                url: "/api/v1/users.forgotPassword",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    email: this.input.email,
                },
            }).then((response) => {
                if (response.data.success) {
                    this.success_message =
                        "You should receive an email at " +
                        this.input.email +
                        " within 5-10 minutes with instructions to reset your password";
                    this.show_form = false;
                }
            });
        },
    },
};
</script>

<style scoped>
.forgot-password-form {
    height: 600px;
    position: relative;
    background-color: #111111;
}
.forgot-password-form form {
    max-width: 320px;
    width: 90%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 40px;
    background-color: #1e2833;
    color: #fff;
    overflow: auto;
    border-radius: 4px;
}
.email-input {
    background: none;
    padding: 10px 5px;
    margin: 8px 0;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    width: 90%;
}
.forgot-password-button {
    width: 50%;
    font-size: large;
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    color: #fff;
}
.forgot-password-button:hover {
    opacity: 0.8;
}
.forgot-password-button:active {
    transform: translateY(1px);
}
.forgot-password-button:focus {
    outline: none;
}
.forgot-password-button::-moz-focus-inner {
    border: 0;
}
</style>

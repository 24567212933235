<template>
    <div v-if="can_submit" class="fmo-audit-form-container">
        <div class="fmo-audit-form">
            <div class="form-header">
                <div class="form-header-title">Facility Medical Outcome Audit Form</div>
            </div>
            <div class="form-content">
                <div class="input-item">
                    <div class="input-label">Radiologist (or group):</div>
                    <select class="select-css" v-model="rad_name">
                        <option v-for="rad in fmo_audit_rad_list" v-bind:key="rad">
                            {{ rad }}
                        </option>
                    </select>
                </div>
                <div class="input-item">
                    <div class="input-label">Facility Location:</div>
                    <select class="select-css" v-model="location">
                        <option v-for="loc in fmo_audit_location_list" v-bind:key="loc">
                            {{ loc }}
                        </option>
                    </select>
                </div>
                <div class="input-item">
                    <div class="input-label">Beginnning Date:</div>
                    <input type="date" v-model="date_begin" />
                </div>
                <div class="input-item">
                    <div class="input-label">Ending Date:</div>
                    <input type="date" v-model="date_end" />
                </div>
                <div class="input-item">
                    <div class="input-label">True Negative (TN):</div>
                    <input type="number" step="1" min="0" v-model="true_negative" />
                </div>
                <div class="input-item">
                    <div class="input-label">False Negative (FN):</div>
                    <input type="number" step="1" min="0" v-model="false_negative" />
                </div>
                <div class="input-item">
                    <div class="input-label">True Positive (TP):</div>
                    <input type="number" step="1" min="0" v-model="true_positive" />
                </div>
                <div class="input-item">
                    <div class="input-label">False Positive (FP):</div>
                    <input type="number" step="1" min="0" v-model="false_positive" />
                </div>
                <div class="input-item">
                    <div class="input-label">False Positive Screening (FP1):</div>
                    <input type="number" step="1" min="0" v-model="false_positive_screening" />
                </div>
                <div class="input-item">
                    <div class="input-label">False Positive Biopsy Rec. (FP2):</div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        v-model="false_positive_biopsy_rec"
                    />
                </div>
                <div class="input-item">
                    <div class="input-label">False Positive Biopsy Done (FP3):</div>
                    <input
                        type="number"
                        step="1"
                        min="0"
                        v-model="false_positive_biopsy_done"
                    />
                </div>
                <table class="calc-table">
                    <tr>
                        <td><div class="calc-label">PPV(1):</div></td>
                        <td>
                            <div class="calc-value">{{ PPV1_calc }}%</div>
                        </td>
                        <td><div class="calc-note">TP / (TP + FP1)</div></td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">PPV(2):</div></td>
                        <td>
                            <div class="calc-value">{{ PPV2_calc }}%</div>
                        </td>
                        <td><div class="calc-note">TP / (TP + FP2)</div></td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">PPV(3):</div></td>
                        <td>
                            <div class="calc-value">{{ PPV3_calc }}%</div>
                        </td>
                        <td><div class="calc-note">TP / (TP + FP3)</div></td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">Sensitivity:</div></td>
                        <td>
                            <div class="calc-value">{{ sensitivity_calc }}%</div>
                        </td>
                        <td><div class="calc-note">TP / (TP + FN)</div></td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">Specificity:</div></td>
                        <td>
                            <div class="calc-value">{{ specificity_calc }}%</div>
                        </td>
                        <td><div class="calc-note">TN / (TN + FP1)</div></td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">Cancer Detection Rate:</div></td>
                        <td>
                            <div class="calc-value">{{ cancer_detection_rate_calc }}%</div>
                        </td>
                        <td>
                            <div class="calc-note">TP / Total Studies "per 1000"</div>
                        </td>
                    </tr>
                    <tr>
                        <td><div class="calc-label">Recall Rate:</div></td>
                        <td>
                            <div class="calc-value">{{ recall_rate_calc }}%</div>
                        </td>
                        <td><div class="calc-note">(TP + FP) / Total Studies</div></td>
                    </tr>
                </table>
                <div class="input-item">
                    <textarea v-model="comments" rows="5" placeholder="Comments"></textarea>
                </div>
                <div class="submit-button-container">
                    <button class="styled-button submit-button" v-on:click="submitForm()">
                        Submit Audit
                    </button>
                </div>
                <div class="success-message green" v-if="success_message !== ''">
                    {{ success_message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config.js";
import axios from "axios";

export default {
    name: "FMOAuditForm",
    data() {
        return {
            rad_name: "",
            location: "",
            true_negative: 0,
            false_negative: 0,
            true_positive: 0,
            false_positive: 0,
            false_positive_screening: 0,
            false_positive_biopsy_rec: 0,
            false_positive_biopsy_done: 0,
            date_begin: "",
            time_begin: "00:00",
            date_end: "",
            time_end: "00:00",
            comments: "",
            success_message: "",
        };
    },
    created() {
        this.rad_name = this.fmo_audit_rad_list[0];
        this.location = this.fmo_audit_location_list[0];
        this.score = this.fmo_audit_scores[0];
        this.modality = this.fmo_audit_modalities[0];
    },
    computed: {
        can_submit() {
            return this.$store.getters.hasPermission("fmo_audit_submit");
        },
        form_entry_username() {
            return this.$store.state.userName;
        },
        fmo_audit_rad_list() {
            return config.fmo_audit_rad_list;
        },
        fmo_audit_location_list() {
            return config.location_list;
        },
        TN_num() {
            return Number(this.true_negative);
        },
        FN_num() {
            return Number(this.false_negative);
        },
        TP_num() {
            return Number(this.true_positive);
        },
        FP_num() {
            return Number(this.false_positive);
        },
        total_studies() {
            return this.TN_num + this.FN_num + this.TP_num + this.FP_num;
        },
        FP1_num() {
            return Number(this.false_positive_screening);
        },
        FP2_num() {
            return Number(this.false_positive_biopsy_rec);
        },
        FP3_num() {
            return Number(this.false_positive_biopsy_done);
        },
        PPV1_calc() {
            if (this.TP_num + this.FP1_num > 0) {
                return ((this.TP_num / (this.TP_num + this.FP1_num)) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        PPV2_calc() {
            if (this.TP_num + this.FP2_num > 0) {
                return ((this.TP_num / (this.TP_num + this.FP2_num)) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        PPV3_calc() {
            if (this.TP_num + this.FP3_num > 0) {
                return ((this.TP_num / (this.TP_num + this.FP3_num)) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        sensitivity_calc() {
            if (this.TP_num + this.FN_num > 0) {
                return ((this.TP_num / (this.TP_num + this.FN_num)) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        specificity_calc() {
            if (this.FP1_num + this.TN_num > 0) {
                return ((this.TN_num / (this.FP1_num + this.TN_num)) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
        cancer_detection_rate_calc() {
            if (this.total_studies > 0) {
                return ((this.TP_num / this.total_studies) * 1000).toFixed(2);
            } else {
                return 0;
            }
        },
        recall_rate_calc() {
            if (this.total_studies > 0) {
                return (((this.TP_num + this.FP_num) / this.total_studies) * 100).toFixed(2);
            } else {
                return 0;
            }
        },
    },
    methods: {
        submitForm() {
            this.success_message = "";
            axios({
                method: "post",
                url: config.bra_api_url + "/fmo-audit-submit",
                data: {
                    userId: this.$store.state.userId,
                    authToken: this.$store.state.authToken,
                    audit_obj: {
                        rad_name: this.rad_name,
                        false_negative: this.false_negative,
                        false_positive: this.false_positive,
                        true_negative: this.true_negative,
                        true_positive: this.true_positive,
                        false_positive_biopsy_done: this.false_positive_biopsy_done,
                        false_positive_biopsy_rec: this.false_positive_biopsy_rec,
                        false_positive_screening: this.false_positive_screening,
                        location: this.location,
                        datetime_begin: this.date_begin + " " + this.time_begin,
                        datetime_end: this.date_end + " " + this.time_end,
                        comments: this.comments,
                    },
                },
            }).then((result) => {
                if (result.data.success) {
                    this.success_message =
                        "Added Audit: " +
                        result.data.audit_obj.rad_name +
                        " (" +
                        result.data.audit_obj.datetime_begin +
                        " - " +
                        result.data.audit_obj.datetime_end +
                        ")";
                }
                this.$store.dispatch("fetchFMOAudits");
            });
        },
    },
};
</script>

<style scoped>
.fmo-audit-form-container {
    margin: 10px;
}

.form-header {
    color: #fff;
    background: #151515;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid #999;
    border-left: 2px solid #999;
    border-right: 2px solid #999;
    border-bottom: 2px solid #999;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 470px;
}

.form-header-title {
    padding: 10px;
    font-size: large;
    padding-left: 20px;
}

.form-content {
    width: 450px;
    background: #1e2833;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    border-left: 2px solid #999;
    display: flex;
    flex-direction: column;
}

.form-header-close {
    cursor: pointer;
    padding: 10px;
    font-size: large;
    border-top-right-radius: 10px;
    border-left: 1px solid #999;
}

.form-header-close:hover {
    background: rgba(255, 255, 255, 0.2);
}

.success-message {
    font-size: 12px;
    text-align: center;
}

.green {
    color: #00ff99;
}

input[type="text"],
input[type="number"] {
    background: none;
    padding: 5px;
    font-size: large;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: inline-block;
    width: 90%;
}

input[type="number"] {
    background: black;
    width: 100px;
}

.input-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-right: 40px;
}

.input-label {
    font-size: medium;
    padding-bottom: 2px;
    margin-right: 10px;
    color: #aaa;
}

.calc-table {
    margin-top: 20px;
    border-collapse: collapse;
}

.calc-label {
    font-size: small;
    text-align: right;
    padding-right: 5px;
}

.calc-value {
    font-size: small;
}

.calc-note {
    font-family: monospace;
    font-size: smaller;
    color: #888;
    padding-left: 10px;
}

.styled-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 14px;
}
.styled-button:hover {
    opacity: 0.8;
}
.styled-button:active {
    transform: translateY(1px);
}
.styled-button:focus {
    outline: none;
}
.styled-button::-moz-focus-inner {
    border: 0;
}

.submit-button-container {
    width: 90%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.submit-button {
    padding: 10px;
    font-size: large;
}

textarea {
    width: 350px;
    background: #111;
    color: #999;
    margin-top: 20px;
    border-radius: 4px;
    padding: 7px;
}

textarea:focus {
    outline: none !important;
    border: 1px solid #5af;
}

.select-css {
    display: block;
    font-size: small;
    font-family: sans-serif;
    font-weight: 700;
    color: #ccc;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 200px;
    box-sizing: border-box;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
        linear-gradient(to bottom, #000000 0%, #222222 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
    display: none;
}
.select-css:hover {
    border-color: #888;
}
.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 2px rgba(59, 153, 252, 0.7);
    color: #eee;
    outline: none;
}
.select-css option {
    font-weight: normal;
}
</style>

<template>
    <div class="peer-review-container">
        <div class="form-container"><PeerReviewForm /></div>
        <div class="search-container"><PeerReviewSearch /></div>
    </div>
</template>

<script>
import PeerReviewForm from "@/components/PeerReviewForm.vue";
import PeerReviewSearch from "@/components/PeerReviewSearch.vue";

export default {
    name: "PeerReview",
    components: {
        PeerReviewForm,
        PeerReviewSearch,
    },
};
</script>

<style scoped>
.peer-review-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 80vh;
}

.form-container {
    width: 440px;
}

.search-container {
    width: calc(100vw - 500px);
}
</style>

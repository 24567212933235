<template>
    <div class="support">
        <SupportContactForm />
    </div>
</template>

<script>
import SupportContactForm from "@/components/SupportContactForm.vue";

export default {
    name: "support",
    components: {
        SupportContactForm,
    },
};
</script>

<style scoped>
.support {
    text-align: left;
    display: inline-block;
    width: 100%;
}
</style>

<template>
    <div class="component-container">
        <div class="none-message" v-if="this.messagesLength == 0">
            No text chat requests from the last 24 hours.
        </div>
        <table v-if="this.messagesLength !== 0">
            <thead>
                <td>Name</td>
                <td>Time</td>
                <td>Message</td>
                <td>
                    <!-- Text Chat -->
                </td>
                <td>
                    <!-- Mark as read button -->
                </td>
            </thead>
            <tr
                v-for="message in this.messages"
                v-bind:key="message.room_id"
                v-bind:class="{ unreadmessage: message.read_status == 0 }"
            >
                <td>{{ message.from_user_fullname }}</td>
                <td class="date-field">{{ getDateString(message.date_insert) }}</td>
                <td class="message-text-field">
                    <vue-simple-markdown :source="message.message_text"></vue-simple-markdown>
                </td>
                <td>
                    <button class="chat-button" v-on:click="openTextChat(message)">
                        Text Chat with {{ message.from_user_fullname }}
                    </button>
                </td>
                <td>
                    <button
                        class="chat-button"
                        v-if="message.read_status == 0"
                        v-on:click="markRead(message)"
                    >
                        Mark as Read
                    </button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import Vue from "vue";
import VueSimpleMarkdown from "vue-simple-markdown";
//import "vue-simple-markdown/dist/vue-simple-markdown.css";
Vue.use(VueSimpleMarkdown);

export default {
    name: "RecentMessageList",
    computed: {
        messages() {
            return this.$store.state.recent_messages;
        },
        messagesLength() {
            if (typeof this.$store.state.recent_messages == "undefined") {
                return 0;
            } else {
                return Object.keys(this.$store.state.recent_messages).length;
            }
        },
    },
    methods: {
        getDateString(in_date) {
            let date = new Date(in_date);
            var offset = new Date().getTimezoneOffset();
            date.setMinutes(date.getMinutes() - offset);
            return date.toLocaleTimeString() + "  (" + date.toLocaleDateString() + ")";
        },
        getUserById(user_id) {
            let user_obj = this.$store.state.users.find(function (user) {
                return user._id == user_id;
            });
            return user_obj;
        },
        markRead(message) {
            this.$store.dispatch("markRoomRead", { room_id: message.room_id });
        },
        openTextChat(message) {
            let chat_window = this.$store.getters.getTextChatWindowByUsername(
                message.from_user_username
            );
            if (typeof chat_window !== "undefined") {
                this.$store.commit("openTextChatWindow", message.from_user_username);
            } else {
                let textChatObj = {
                    x: this.$store.state.text_chat_window_next_xy,
                    y: this.$store.state.text_chat_window_next_xy,
                    h: 500,
                    w: 500,
                    title: "Chat with " + message.from_user_fullname,
                    username: message.from_user_username,
                    start_open: true,
                    is_visible: true,
                    show_button: false,
                };
                this.$store.commit("addTextChatWindow", textChatObj);
            }
            this.markRead(message);
        },
    },
};
</script>

<style scoped>
table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    text-align: left;
}

table thead {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid white;
    text-align: center;
}

table td,
table thead td {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

tr {
    border-bottom: 1px solid #999;
}

.unreadmessage {
    color: #09f;
    background: #202020;
}

tr:not(.unreadmessage) {
    color: #777;
    background: #151515;
}

.date-field {
    font-size: 12px;
    font-family: "Lucida Console", Monaco, monospace;
}

.message-text-field {
    white-space: break-spaces;
}

.chat-button {
    cursor: pointer;
    background: #214a80;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    text-shadow: none;
    outline: none;
    color: #fff;
    font-size: 12px;
}

.chat-button:hover {
    opacity: 0.8;
}

.chat-button:active {
    transform: translateY(1px);
}

.chat-button:focus {
    outline: none;
}

.chat-button::-moz-focus-inner {
    border: 0;
}

.none-message {
    padding: 15px;
}

a {
    color: #5af;
}

@media all and (max-width: 1600px) {
    .chat-button {
        font-size: 10px;
    }
    table,
    table thead {
        font-size: 12px;
    }
}
</style>

<style>
.vue-simple-markdown a {
    color: #5af;
}
</style>
